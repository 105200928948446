import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import ReactGA from 'react-ga';
import { ANALYTICS_KEY, FIREBASE_CONFIG } from './configuration';
import { store, persistor } from './redux/store/configureStore';
import AppRouter from './AppRouter';
import styled from 'styled-components';

// Create a styled wrapper for the ToastContainer
const StyledToastContainer = styled(ToastContainer)`
  // Override toast container background
  &&&.Toastify__toast-container {
    margin-bottom: 10px;
  }

  // Dark mode styling for toasts
  .Toastify__toast {
    background-color: #262626;
    color: #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    font-family: inherit;
  }

  // Style toast body
  .Toastify__toast-body {
    font-size: 14px;
    padding: 6px 4px;
  }

  // Success toast type
  .Toastify__toast--success {
    background-color: #2d3748;
    border-left: 4px solid #48bb78;
  }

  // Error toast type
  .Toastify__toast--error {
    background-color: #2d3748;
    border-left: 4px solid #f56565;
  }

  // Info toast type
  .Toastify__toast--info {
    background-color: #2d3748;
    border-left: 4px solid #4299e1;
  }

  // Warning toast type
  .Toastify__toast--warning {
    background-color: #2d3748;
    border-left: 4px solid #ed8936;
  }

  // Style the close button
  .Toastify__close-button {
    color: #a0a0a0;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
      color: #e0e0e0;
    }
  }

  // Style the progress bar
  .Toastify__progress-bar {
    background: linear-gradient(to right, #4a5568, #718096);
  }
`;

class App extends Component {
  componentDidMount() {
    if (ANALYTICS_KEY) {
      ReactGA.initialize(ANALYTICS_KEY);
    }
    if (FIREBASE_CONFIG.apiKey) {
      firebase.initializeApp(FIREBASE_CONFIG);
      firebase.auth().signInAnonymously().catch((error) => {
        console.error(error);
      });
    }
  }
  
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App products-bg">
            <AppRouter />
            <StyledToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
              theme="dark" // Set theme to dark (built-in support)
            />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;