import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import CreditPriceMonths from '../../shared/creditPriceMonths/CreditPriceMonths';
import { currencyFormat, urlFormat } from '../../utils/format';
import PremiumProductModal from '../premiumProductModal/PremiumProductModal';

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

class ProductListItem extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            amount: 1,
            isHovered: false
        };
    }

    itemHasDiscount() {
        return this.props.price_with_discount 
            && parseFloat(this.props.price_with_discount) < parseFloat(this.props.price);
    }

    getFinalPrice() {
        return !this.itemHasDiscount() ? this.props.price : this.props.price_with_discount;
    }

    getItemType() {
        return !this.props.product.items ? 'item' : 'pack';
    }
    
    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }
    
    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }
    
    render() {
        let price = this.getFinalPrice();
        const productLink = `/${this.getItemType()}/${urlFormat(this.props.product.model)}/${this.props.product.id}`;
        const isOutOfStock = this.props.stock <= 0 && !this.props.product.items;
        const discountPercentage = this.itemHasDiscount() ? 
            Math.round(100 - (parseFloat(this.props.price_with_discount) * 100 / parseFloat(this.props.price))) : 0;
        
        return (
            <div className="product-item-card">
                <style>
                    {`
                        .product-item-card {
                            background-color: #262626;
                            border-radius: 10px;
                            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
                            overflow: hidden;
                            transition: all 0.3s ease;
                            position: relative;
                            height: 400px;
                            display: flex;
                            flex-direction: column;
                            border: 1px solid #333;
                        }
                        
                        .product-item-card:hover {
                            transform: translateY(-5px);
                            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
                            border-color: #444;
                        }
                        
                        .product-image-container {
                            position: relative;
                            width: 100%;
                            height: 154px;
                            background-color: #1a1a1a;
                            border-bottom: 1px solid #333;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                        }

                        .product-image {
                            max-width: 100%;
                            min-height: 100%;
                            object-fit: contain;
                            padding: 10px;
                            transition: transform 0.4s ease;
                        }
                        
                        .product-item-card:hover .product-image {
                            transform: scale(1.05);
                        }
                        
                        .product-details {
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            color: #e0e0e0;
                        }
                        
                        .sold-units {
                            font-size: 10px;
                            color: #999;
                            margin-bottom: 3px;
                            height: 15px;
                        }
                        
                        .product-title {
                            font-size: 13px;
                            font-weight: 500;
                            margin-bottom: 8px;
                            padding-bottom: 8px;
                            border-bottom: 1px solid #333;
                            color: #e0e0e0;
                            height: 40px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        
                        .price-section {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 8px;
                        }
                        
                        .price-container {
                            display: flex;
                            flex-direction: column;
                        }
                        
                        .final-price {
                            font-size: 16px;
                            font-weight: 700;
                            color: #fff;
                        }
                        
                        .original-price {
                            font-size: 11px;
                            text-decoration: line-through;
                            color: #999;
                        }
                        
                        .shipping-icon {
                            color: #28a745;
                            font-size: 16px;
                        }
                        
                        .credit-section, .detail-section, .stock-section {
                            margin-bottom: 8px;
                        }
                        
                        .detail-link {
                            color: #8bb9f0;
                            text-decoration: none;
                            font-size: 13px;
                            padding: 0;
                            display: inline-block;
                        }
                        
                        .detail-link:hover {
                            color: #a3c9f8;
                            text-decoration: underline;
                        }
                        
                        .stock-info {
                            font-size: 11px;
                            color: #999;
                        }
                        
                        .button-container {
                            margin-top: auto;
                            padding-top: 10px;
                        }
                        
                        .add-cart-button {
                            background-color: #1a2c42;
                            border: none;
                            width: 100%;
                            padding: 8px 0;
                            border-radius: 6px;
                            color: white;
                            font-weight: 500;
                            transition: background-color 0.2s ease;
                            font-size: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        
                        .add-cart-button:hover {
                            background-color: #2c3e50;
                        }
                        
                        .add-cart-button:disabled {
                            background-color: #2d3748;
                            opacity: 0.6;
                            cursor: not-allowed;
                        }
                        
                        .discount-badge {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            background-color: #e63946;
                            color: white;
                            padding: 3px 8px;
                            border-radius: 4px;
                            font-size: 11px;
                            font-weight: bold;
                            z-index: 2;
                        }
                        
                        .unavailable-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.7);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 3;
                        }
                        
                        .unavailable-label {
                            background-color: rgba(220, 53, 69, 0.9);
                            color: white;
                            padding: 5px 10px;
                            border-radius: 4px;
                            font-weight: bold;
                            font-size: 14px;
                            transform: rotate(-15deg);
                            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
                            text-transform: uppercase;
                        }
                        
                        .cart-icon {
                            margin-left: 8px;
                        }
                    `}
                </style>

                <div 
                    className="product-item-card"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {/* Discount Badge */}
                    {discountPercentage > 0 && (
                        <div className="discount-badge">{discountPercentage}% OFF</div>
                    )}
                    
                    {/* Out of Stock Overlay */}
                    {isOutOfStock && (
                        <div className="unavailable-overlay">
                            <div className="unavailable-label">Agotado</div>
                        </div>
                    )}
                    
                    {/* Product Image */}
                    <div className="product-image-container">
                        <Nav.Link href={productLink}>
                            <img 
                                className="product-image" 
                                src={this.props.photos[0]} 
                                alt={this.props.model || "Producto"}
                            />
                        </Nav.Link>
                    </div>
                    
                    {/* Product Details */}
                    <div className="product-details">
                        {/* Sold Units */}
                        <div className="sold-units">
                            {this.props.product.sold_units > 0 && (
                                `${this.props.product.sold_units} ${this.props.product.sold_units === 1 ? 'Vendido' : 'Vendidos'}`
                            )}
                        </div>
                        
                        {/* Product Title */}
                        <div className="product-title">
                            {this.props.product.title ? this.props.product.title + " - " : ""} 
                            {this.props.model}
                        </div>
                        
                        {/* Price Section */}
                        {!(price < 0.1 || this.props.product.items) && (
                            <div className="price-section">
                                <div className="price-container">
                                    <div className="final-price">${currencyFormat(price)}</div>
                                    
                                    {this.itemHasDiscount() && (
                                        <div className="original-price">${currencyFormat(this.props.price)}</div>
                                    )}
                                </div>
                                
                                <div className="shipping-icon">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-shipping">Envío Gratis</Tooltip>}
                                    >
                                        <i className="fa fa-truck"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        )}
                        
                        {/* Credit Information */}
                        <div className="credit-section">
                            <CreditPriceMonths 
                                price={price} 
                                hasItems={this.props.product.items} 
                                hasInstallments={this.props.product.hasInstallments}
                            />
                        </div>
                        
                        {/* Premium Modal */}
                        {(price < 0.1 || this.props.product.items) && (
                            <div className="credit-section">
                                <PremiumProductModal model={this.props.model} />
                            </div>
                        )}
                        
                        {/* Details Link */}
                        <div className="detail-section">
                            <Nav.Link href={productLink} className="detail-link">
                                Detalle
                            </Nav.Link>
                        </div>
                        
                        {/* Stock Information */}
                        <div className="stock-section">
                            <div className="stock-info">
                                {!this.props.product.items ? (
                                    <>Disponible: {this.props.stock}</>
                                ) : (
                                    <>Disponibilidad inmediata</>
                                )}
                            </div>
                        </div>
                        
                        {/* Add to Cart Button */}
                        <div className="button-container">
                            {!REACT_APP_INFORMATIVE_MODE && !(price < 0.1 || this.props.product.items) && (
                                <Button 
                                    className="add-cart-button"
                                    onClick={e => this.props.addToKartHandler("kart", {...this.props.product, amount: 1})}
                                    disabled={isOutOfStock}
                                >
                                    Agregar <i className="fa fa-cart-plus cart-icon"></i>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductListItem;