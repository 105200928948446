import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import ProductExplorer from '../../../shared/productExplorer/ProductExplorer'
import Loader from '../../../shared/loader/loader';
import Footer from '../../../shared/footer/footer';
import queryString from 'query-string';
import ReactGA from 'react-ga';

class Explorer extends Component {

  constructor(props) {
    super(props);
    this.props = props;
  
    this.state = {
      isLoading: false,
      products: [],
      selectedCategory: this.props.match.params.category ? this.props.match.params.category : 'guitarras',
      selectedSubcategory: queryString.parse(this.props.location.search).subcategory ? queryString.parse(this.props.location.search).subcategory : "all",
    }

    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
   
    return (
        <Container fluid>
            <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
            <Navigation category={this.state.selectedCategory} auth={this.props.auth}/>
            {!this.state.isLoading ? (
              <ProductExplorer
                category={this.state.selectedCategory}
                subcategory={this.state.selectedSubcategory}
                paginationBgColor="#008A9D"
                paginationFontColor="black"
                previousPage={this.props.previousPage}
                nextPage={this.props.nextPage}
                pages={this.props.pages}
                currentPage={this.props.nextPage-1}
                kartName="kart"
              >
              </ProductExplorer>):
              (<Loader/>)
            }
            <Footer/> 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    products: state.commerce.products,
    pages: state.commerce.pages,
    previousPage: state.commerce.previousPage,
    nextPage: state.commerce.nextPage,
  }
}
  
const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Explorer);
