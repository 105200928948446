import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import ProductListItem from '../productListItem/ProductListItem';
import { selectPage, addItemToKart } from '../../redux/actions';
import Loader from '../loader/loader';
import styled from 'styled-components';

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            products: this.props.products,
            modalShow: false,
            selectedCategory: this.props.category,
            currentPage: this.props.currentPage ? parseInt(this.props.currentPage) : 1,
            pages: this.props.pages,
            page: 1,
            windowWidth: typeof window !== 'undefined' ? window.innerWidth : 1200
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    // Determina cuántos productos por fila basado en el ancho de la ventana
    getProductsPerRow() {
        const { windowWidth } = this.state;
        
        if (windowWidth >= 1400) return 6;      // xxl screens
        if (windowWidth >= 1200) return 5;      // xl screens
        if (windowWidth >= 992) return 4;       // lg screens
        if (windowWidth >= 768) return 3;       // md screens
        if (windowWidth >= 576) return 2;       // sm screens
        return 1;                               // xs screens
    }

    // Obtiene el tamaño de columna Bootstrap basado en productos por fila
    getColSize(productsPerRow) {
        const colSizes = {
            1: "12",
            2: "6",
            3: "4",
            4: "3",
            5: "auto", // auto para 5 columnas (personalizado)
            6: "2"
        };
        return colSizes[productsPerRow] || "auto";
    }

    buildProductItem(product, index, productsPerRow) {
        if (!product) return null;
        
        const colSize = this.getColSize(productsPerRow);
        
        return (
            <Col 
                key={`product-${product.id}-${index}`} 
                xs={12} 
                sm={productsPerRow > 1 ? 6 : 12} 
                md={productsPerRow > 2 ? 4 : (productsPerRow > 1 ? 6 : 12)} 
                lg={productsPerRow > 3 ? 3 : (productsPerRow > 2 ? 4 : (productsPerRow > 1 ? 6 : 12))}
                xl={productsPerRow > 4 ? (productsPerRow === 6 ? 2 : "auto") : (productsPerRow > 3 ? 3 : (productsPerRow > 2 ? 4 : (productsPerRow > 1 ? 6 : 12)))}
                className="mb-4 product-item-container"
            >
                <ProductListItem 
                    product={product} 
                    key={product.id} 
                    photos={product.photos} 
                    model={product.model} 
                    price={product.price} 
                    price_with_discount={product.price_with_discount} 
                    stock={product.stock} 
                    description={product.description} 
                    addToKartHandler={this.props.addItemToKart}
                />
            </Col>
        );
    }

    getRowsOfProducts(products) {
        const productsPerRow = this.getProductsPerRow();
        const rows = [];
        
        // Crear filas de productos adaptativas
        for (let i = 0; i < products.length; i += productsPerRow) {
            const rowProducts = [];
            
            for (let j = 0; j < productsPerRow; j++) {
                const index = i + j;
                if (index < products.length) {
                    rowProducts.push(this.buildProductItem(products[index], index, productsPerRow));
                }
            }
            
            rows.push(
                <Row key={`row-${i}`} className="product-row">
                    {rowProducts}
                </Row>
            );
        }
        
        return rows;
    }

    getStyledPagination() {
        return styled(Pagination)`
            margin-top: 20px;
            margin-bottom: 20px;
            
            .page-item .page-link {
                z-index: 1 !important;
                color: #e0e0e0 !important;
                background-color: #2a2a2a !important;
                border-color: #3a3a3a !important;
                transition: all 0.2s ease;
            }

            .page-item:hover .page-link {
                background-color: #3c3c3c !important;
                border-color: #4a4a4a !important;
            }

            .page-item.active .page-link {
                z-index: 1 !important;
                color: white !important;
                background-color: #1a2c42 !important;
                border-color: #1a2c42 !important;
            }
            
            .page-item.disabled .page-link {
                color: #6c757d !important;
                background-color: #242424 !important;
                border-color: #333 !important;
            }
        `;
    }

    getPaginationItems() {
        const items = [];
        if(this.props.pages <= 5 || this.props.currentPage <= 3) {
            for(let i = 0; i < Math.min(this.props.pages, 5); i+=1) {
                items.push(
                    <Pagination.Item 
                        key={i+1} 
                        active={i+1 === this.props.nextPage-1} 
                        onClick={(e) => this.props.selectPage(this.state.selectedCategory, i+1)}
                    >
                        {i+1}
                    </Pagination.Item>
                );
            }
        } else {
            for(let i = this.props.currentPage - 3; i < Math.min(this.props.pages, this.props.currentPage + 2); i+=1) {
                items.push(
                    <Pagination.Item 
                        key={i+1} 
                        active={i+1 === this.props.nextPage-1} 
                        onClick={(e) => this.props.selectPage(this.state.selectedCategory, i+1)}
                    >
                        {i+1}
                    </Pagination.Item>
                );
            }
        }

        return items;
    }

    render() {
        const products = this.props.products;
        const productRows = this.getRowsOfProducts(products);
        const StyledPagination = this.getStyledPagination();
        const paginationItems = this.getPaginationItems();
        const productsPerRow = this.getProductsPerRow();

        return (
            <Col className="products-bg">
                <style>
                    {`
                        .products-bg {
                            background-color: #1f1f1f;
                            padding: 20px 0;
                        }
                        
                        .no-products {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-height: 200px;
                            width: 100%;
                            color: #e0e0e0;
                            font-size: 18px;
                            background-color: #262626;
                            border-radius: 8px;
                            margin: 20px 0;
                        }
                        
                        .product-item-container {
                            display: flex;
                            padding: 8px;
                        }
                        
                        .product-item-container > div {
                            width: 100%;
                            height: 100%;
                        }
                        
                        .product-row {
                            margin-left: -8px;
                            margin-right: -8px;
                            justify-content: flex-start;
                        }
                        
                        /* Layout for 5 columns (not natively supported by Bootstrap) */
                        @media (min-width: 1200px) {
                            .col-xl-auto {
                                flex: 0 0 20%;
                                max-width: 20%;
                            }
                        }
                        
                        /* Adjust spacing for better use of screen space */
                        @media (min-width: 1400px) {
                            .container-fluid {
                                padding-left: 30px;
                                padding-right: 30px;
                            }
                        }
                    `}
                </style>
                
                <Container fluid>
                    {!this.props.isLoading ? (
                        products.length > 0 ? (
                            <div className="products-container">
                                {productRows}
                            </div>
                        ) : (
                            <div className="no-products">No hay productos disponibles</div>
                        )
                    ) : (
                        <Loader />
                    )}
                </Container>
                
                <Container fluid>
                    {this.props.pages > 1 ? (
                        <StyledPagination className="justify-content-center">
                            <Pagination.First onClick={(e) => this.props.selectPage(this.state.selectedCategory, 1)}/>
                            
                            {this.props.previousPage > 0 ? (
                                <Pagination.Prev onClick={(e) => this.props.selectPage(this.state.selectedCategory, this.props.previousPage)}/>
                            ) : null}
                            
                            {paginationItems}
                            
                            {this.props.nextPage <= this.props.pages ? (
                                <Pagination.Next onClick={(e) => { 
                                    this.setState({...this.state, page: this.props.nextPage}); 
                                    this.props.selectPage(this.state.selectedCategory, this.props.nextPage)
                                }} />
                            ) : null}
                            
                            <Pagination.Last onClick={(e) => this.props.selectPage(this.state.selectedCategory, this.props.pages)} />
                        </StyledPagination>
                    ) : null}
                </Container>
            </Col>
        );
    }
}

const mapStateToProps = function(state) {
    return {
        isLoading: state.global.isLoading
    }
}

const mapDispatchToProps = dispatch => ({
    selectPage: (selectedCategory, newPage) => dispatch(selectPage(selectedCategory, newPage)),
    addItemToKart: (kartName, item) => dispatch(addItemToKart(kartName, item)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductList);