import React, { Component } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

class CollapsibleFilterList extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            expanded: true,
        };

        this.toogleFilter = this.toogleFilter.bind(this);
    }

    toogleFilter(){
        const expanded = !this.state.expanded;
        this.setState({...this.state, expanded});
    }

    getOptions(){
        const StyledFormCheck = styled(Form.Check)`
            font-family: 'Raleway', sans-serif;
            font-weight: bolder;
            font-size: 13px;
            color: #e0e0e0;
            input[type="checkbox"],
            input[type="radio"] {
                position: absolute;
                clip: rect(0, 0, 0, 0);
            }
            input[type="checkbox"] + label::before,
            input[type="radio"] + label::before {
                content: '\xa0';
                display: inline-block;
                margin: .2em .9em;
                width: .8em;
                height: .8em;
                line-height: .75em;
                transition: all .5s;
                border: 1px solid #3a3a3a;
                background-color: #262626;
            }

            input[type="checkbox"]:checked + label::before,
            input[type="radio"]:checked + label::before {
                content: '\xa0';
                background: #1a2c42;
                border: 1px solid #e0e0e0;
                box-shadow: inset 0 0 0 1px #262626, 0 0 0 1px #1a2c42;
            }
        `;
        return this.props.options.map(option => <StyledFormCheck checked={this.props.optionSelected.toUpperCase() === option[this.props.renderKey].toUpperCase()} onChange={(e) => this.props.handler(option)} key={option.id} label={option[this.props.renderKey].toUpperCase()} id={this.props.title+option.id}/>);
    }

    getStyledButton(){
        return styled(Button)`
            all: unset;
            text-decoration: none;
            text-align: center;
            width: 100%;
            font-size: 14px;
            color: #e0e0e0;

            &:hover {
                border-color: transparent !important;
                background-color: transparent !important;
                color: white !important;
            }

            &:active {
                border-color: transparent !important;
                background-color: transparent !important;
                color: white !important;
            }

            &:focus {
                border-color: transparent !important;
                background-color: transparent !important;
                color: white !important;
            }
            
            .title {
                font-weight: bolder;
                display: inline;
            }
        `;
    }
    
    render() {
        const options = this.getOptions();
        const StyledButton = this.getStyledButton();
        
        return (
            <>
                <style>
                    {`
                        .clickable.border-bottom {
                            border-color: #3a3a3a !important;
                        }
                        .options {
                            background-color: #1f1f1f;
                            padding: 5px;
                        }
                    `}
                </style>
                <Row className="clickable border-bottom my-1 title">
                    <StyledButton
                        onClick={this.toogleFilter}
                        aria-controls="collapse-filter"
                        aria-expanded={this.state.expanded}
                    >
                        <div className="title">
                            {this.props.title}
                            { !this.state.expanded ? <i className="fa fa-angle-down ml-auto"></i> : <i className="fa fa-angle-up ml-auto"></i> }
                        </div>
                    </StyledButton>
                </Row>
                <Collapse in={this.state.expanded}>
                    <div id="collapse-filter" className="text-align-left options">
                        { options }
                    </div>
                </Collapse>
            </>
        );
    }
}

export default CollapsibleFilterList;