import 'react-owl-carousel2/src/owl.carousel.css';

import React, { Component } from 'react';

import {
  Button,
  Nav,
} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactGA from 'react-ga';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Footer from '../../../shared/footer/footer';
import PaymentMethodsModal
  from '../../../shared/paymentMethodsModal/PaymentMethodsModal';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import RewardsModal from '../../../shared/rewardsModal/RewardsModal';
import SecurityModal from '../../../shared/securityModal/SecurityModal';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import FeaturedProducts from './featuredProducts/FeaturedProducts';
import { cmsService } from '../../../services/cms.service';

// Estilos globales para los chevrons del carrusel y mejoras de imágenes
const CarouselStyles = styled.div`
  /* Estilos para los controles del carrusel */
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    margin-top: 0;
    pointer-events: none;
    z-index: 10;
  }
  
  .owl-nav button {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    background-color: rgba(26, 44, 66, 0.7) !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    pointer-events: auto;
    transition: all 0.3s ease;
    position: absolute;
    z-index: 10;
  }
  
  .owl-nav button:hover {
    background-color: rgba(26, 44, 66, 0.9) !important;
    opacity: 1;
  }
  
  .owl-nav button span {
    font-size: 24px;
    color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    font-weight: bold;
  }
  
  .owl-prev {
    left: 10px;
  }
  
  .owl-next {
    right: 10px;
  }
  
  /* Estilos para los puntos del carrusel */
  .owl-dots {
    margin-top: 10px;
    position: relative;
    z-index: 5;
  }
  
  .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px;
    background-color: #3a3a3a;
    transition: all 0.3s ease;
  }
  
  .owl-dots .owl-dot.active span,
  .owl-dots .owl-dot:hover span {
    background-color: #1a2c42;
  }
  
  /* Mejoras visuales para las imágenes */
  .carousel-image-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .carousel-image-container:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .carousel-image-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .carousel-image-container:hover .carousel-image-overlay {
    opacity: 0.4;
  }
  
  /* Efecto sutil de brillo en bordes */
  .carousel-image-container::after {
    content: '';
    position: absolute;
    inset: 0;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    pointer-events: none;
  }
  
  /* Estilo para contenedor de imágenes pequeñas */
  .small-image-container {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    position: relative;
  }
  
  /* Animación de carga para las imágenes */
  @keyframes imageLoad {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .carousel-image {
    animation: imageLoad 0.5s ease-in;
  }
`;

const PaymentMethodBanner = styled(Row)`
  margin: auto;
  padding: 0px 10px 0px 10px;
  width: 80vw;
  background-color: #262626;
  color: #e0e0e0;
  min-height: 90px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }
  
  h6 {
    color: white;
    font-weight: 600;
  }
  
  .sold-items {
    color: #a0a0a0;
  }
  
  .fa {
    color: #e0e0e0;
  }
  
  .clickable:hover {
    cursor: pointer;
    color: white;
  }
`;

const SecurityCard = styled(Row)`
  background-color: #262626;
  margin: 43px 0px 20px 0px;
  min-height: 280px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
  
  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }
  
  h5 {
    color: white;
    font-weight: 600;
    margin-top: 15px;
  }
  
  p {
    color: #a0a0a0;
  }
`;

const SplitBannerContainer = styled(Row)`
  margin: auto;
  padding: 0;
  width: 80vw;
  background-color: #1f1f1f;
  min-height: 90px;
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;

  .align-vertical {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
  }

  .owl-carousel .owl-item img {
    display: block !important;
    width: 100% !important;
    max-height: 366px;
  }
`;

const SplitBanner = styled(Col)`
  height: 80px;
  margin: 0;
  padding: 0;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const SplitBannerLarge = styled(Col)`
  max-height: 366px;
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const SplitBannerLargeHalf = styled(Col)`
  height: 178px;
  margin: 0;
  padding-left: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;

  & > img {
    height: 100%;
    width: 100%;
  }
`;

const LinkToProduct = styled(Nav.Link)`
  padding: 0;
  margin: 0;
`;

// Styled loader component
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
`;

const Loader = styled.div`
  border: 5px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 5px solid #3a3a3a;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Componente mejorado para LinkToProduct
const EnhancedLinkToProduct = ({ href, children, className }) => (
  <LinkToProduct href={href} className={className}>
    <div className="carousel-image-container">
      {children}
      <div className="carousel-image-overlay"></div>
    </div>
  </LinkToProduct>
);

// Opciones base para los carruseles con navegación visible
const baseOptions = {
  nav: true,
  navText: ['<span>&lt;</span>', '<span>&gt;</span>'], // Símbolos de flecha HTML en lugar de texto
  dots: true,
  rewind: true,
  autoplay: true,
  navClass: ['owl-prev', 'owl-next'],
  navContainerClass: 'owl-nav',
  dotsClass: 'owl-dots',
  dotClass: 'owl-dot',
};

const options = {
  ...baseOptions,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1090: {
      items: 1
    }
  }
};

const optionCarouselLarge = {
  ...baseOptions,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1090: {
      items: 1
    }
  }
};

const images = [
  {
    alt: "somos distribuidores autorizados paiste",
    src: "https://i.postimg.cc/mkSSRch0/paistebannermobil.png",
    href: "#"
  },
  {
    alt: "paiste",
    src: "https://i.postimg.cc/jjcdJnr2/paistesoundcenter.png",
    href: "#"
  },
  {
    alt: "pst5 series",
    src: "https://i.postimg.cc/brJTjnqt/pst5banner.png",
    href: "/item/068es14---new-pst-5-essential-set-14-18/1024"
  },
  {
    alt: "paiste heavy rock banner",
    src: "https://i.postimg.cc/rF5jWxfq/paisteheavyrockbanner.png",
    href: "/item/170rset---pst-7-heavy-rock-set-14-16-20/1042"
  },
  {
    alt: "paiste color soundminibanner",
    src: "https://i.postimg.cc/G3WzHqJM/paistecolorsoundminibanner.png",
    href: "/item/1921622----900-cs-red-ride-22-pulgadas-/10973"
  },
  {
    alt: "PST8",
    src: "https://i.postimg.cc/W3K8mnR1/PST8minibanner.png",
    href: "/item/1803114---pst-8-reflector-sound-edge-hi-hat-14-pulgadas-/1076"
  }
];

const CmsUrl = process.env.REACT_APP_CSM_URL;

const SonopuntosImg = () => (
  <div className="carousel-image-container">
    <img 
      className="carousel-image"
      src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/sonopuntos.webp" 
      alt="Tus compras generan sonopuntos"
    />
    <div className="carousel-image-overlay"></div>
  </div>
);

const BoseL1V8 = () => (
  <EnhancedLinkToProduct href="/item/l1-pro8---sistema-pa-activo-portable-con-subwoofer-y-bluetooth/11324">
    <img 
      className="carousel-image"
      src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/L1Pro8_Banner-01.webp" 
      alt="proximamente l1 pro 8"
    />
  </EnhancedLinkToProduct>
);

const BoseL1V8Prices = () => (
  <EnhancedLinkToProduct href="/item/l1-pro16---sistema-pa-activo-portable-con-subwoofer-y-bluetooth/11325">
    <img 
      className="carousel-image"
      src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/L1Pro16-32_Banner-02.webp" 
      alt="l1 pro 8 disponible a partir de diciembre"
    />
  </EnhancedLinkToProduct>
);

const BoseS1ProFR = () => (
  <EnhancedLinkToProduct href="/item/s1-pro-fr---s1-pro-factory-renewed/11166">
    <img 
      className="carousel-image"
      src="https://sonoritmobbpro.s3-us-west-2.amazonaws.com/images/landing_sono/s1probanner2FR.webp" 
      alt="bose s1 pro factory renewed"
    />
  </EnhancedLinkToProduct>
);

const PaymentMethods = (
  <Container fluid>
    <PaymentMethodBanner>
      <Col md={6} lg={3} className="align-vertical">
        <h6>Paga cómodo y seguro</h6>
        <p className="sold-items">Con MercadoPago</p>
      </Col>
      <Col md={6} lg={3} className="align-vertical">
        <h6>3, 6 y 12 meses sin intereses</h6>
        <p className="sold-items">En productos seleccionados</p>
      </Col>
      <Col md={12} lg={3} className="align-vertical">
        <i className="fa fa-3x fa-cc-amex"></i>
        <i className="fa fa-fw"></i>
        <i className="fa fa-3x fa-cc-visa"></i>
        <i className="fa fa-fw"></i>
        <i className="fa fa-3x fa-cc-mastercard"></i>
      </Col>
      <PaymentMethodsModal>
        <Col md={12} lg={3} className="align-vertical clickable">
          <i className="fa fa-2x fa-plus-circle"></i>
          <h6>Ver más medios de pago</h6>
        </Col>
      </PaymentMethodsModal>
    </PaymentMethodBanner>
  </Container>
);

class LandingSonoritmo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeData: null,
      isLoading: true
    };
  }

  componentDidMount() {
    cmsService.getHomeConfig()
      .then(response => {
        this.setState({ 
          homeData: response.data.entries,
          isLoading: false 
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  mapDataToComponent(componentData) {
    if(componentData.type === "BANNERFULL") {
      return (
        <CarouselStyles key={`banner-full-${componentData.position}`}>
          <div className="d-lg-none d-md-block">
            <SplitBannerContainer>
              <OwlCarousel options={options}>
                <SplitBanner lg={6} md={12}>
                  <div className="carousel-image-container">
                    <img
                      className="carousel-image"
                      src={CmsUrl + (componentData.mobile_full_banner_image.path.startsWith('/') ? componentData.mobile_full_banner_image.path : '/' + componentData.mobile_full_banner_image.path)}
                      alt={componentData.mobile_full_banner_alttext}
                    />
                    <div className="carousel-image-overlay"></div>
                  </div>
                </SplitBanner>
              </OwlCarousel>
            </SplitBannerContainer>
          </div>
          <div className="d-none d-lg-block">
            <SplitBannerContainer>
              <SplitBannerLarge lg={12} md={12} className="clickable">
                <div className="carousel-image-container">
                  <img
                    className="carousel-image"
                    src={CmsUrl + (componentData.web_full_banner_image.path.startsWith('/') ? componentData.web_full_banner_image.path : '/' + componentData.web_full_banner_image.path)}
                    alt={componentData.web_full_banner_alttext}
                  />
                  <div className="carousel-image-overlay"></div>
                </div>
              </SplitBannerLarge>
            </SplitBannerContainer>
          </div>
        </CarouselStyles>
      )
    }

    if(componentData.type === "BANNERCOLLECTION") {
      return (
        <CarouselStyles key={`banner-collection-${componentData.position}`}>
          <Container fluid>
            <div className="d-none d-lg-block">
              <SplitBannerContainer>
                <SplitBannerLarge lg={8} md={12} className="clickable">
                  <OwlCarousel options={optionCarouselLarge}>
                    <EnhancedLinkToProduct href={componentData.link1}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image1.path}
                        alt={componentData.alttext1}
                      />
                    </EnhancedLinkToProduct>
                    <EnhancedLinkToProduct href={componentData.link2}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image2.path}
                        alt={componentData.alttext2}
                      />
                    </EnhancedLinkToProduct>
                  </OwlCarousel>
                </SplitBannerLarge>
                <SplitBannerLarge lg={4} className="clickable">
                  <EnhancedLinkToProduct href={componentData.link3}>
                    <SplitBannerLargeHalf lg={12} className="small-image-container">
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image3.path}
                        alt={componentData.alttext3}
                      />
                    </SplitBannerLargeHalf>
                  </EnhancedLinkToProduct>
                  <OwlCarousel
                    options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
                  >
                    <EnhancedLinkToProduct href={componentData.link4}>
                      <SplitBannerLargeHalf lg={12} className="small-image-container">
                        <img
                          className="carousel-image"
                          src={CmsUrl+componentData.image4.path}
                          alt={componentData.alttext4}
                        />
                      </SplitBannerLargeHalf>
                    </EnhancedLinkToProduct>
                  </OwlCarousel>
                </SplitBannerLarge>
              </SplitBannerContainer>
            </div>
            <div className="d-lg-none d-md-block">
              <SplitBannerContainer>
                <SplitBannerLarge md={12} className="clickable">
                  <OwlCarousel
                    options={{ ...optionCarouselLarge, autoplayTimeout: 3000 }}
                  >
                    <EnhancedLinkToProduct href={componentData.link1}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image1.path}
                        alt={componentData.alttext1}
                      />
                    </EnhancedLinkToProduct>
                    <EnhancedLinkToProduct href={componentData.link2}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image2.path}
                        alt={componentData.alttext2}
                      />
                    </EnhancedLinkToProduct>
                  </OwlCarousel>
                </SplitBannerLarge>
              </SplitBannerContainer>
            </div>
            <div className="d-lg-none d-md-block">
              <SplitBannerContainer>
                <SplitBannerLarge md={12} className="clickable">
                  <OwlCarousel options={{ ...optionCarouselLarge, nav: true }}>
                    <EnhancedLinkToProduct href={componentData.link3}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image3.path}
                        alt={componentData.alttext3}
                      />
                    </EnhancedLinkToProduct>
                    <EnhancedLinkToProduct href={componentData.link4}>
                      <img
                        className="carousel-image"
                        src={CmsUrl+componentData.image4.path}
                        alt={componentData.alttext4}
                      />
                    </EnhancedLinkToProduct>
                  </OwlCarousel>
                </SplitBannerLarge>
              </SplitBannerContainer>
            </div>
          </Container>
          <Container fluid>
            <Row>
              <Col lg={{ offset: 1, span: 10 }}>
                <FeaturedProducts
                  brand={componentData.products_carrousel_brand || "PAISTE"}
                />
              </Col>
            </Row>
          </Container>
        </CarouselStyles>
      );
    }

    if(componentData.type === "PRODUCTSCARROUSEL") {
      return(
        <Container fluid key={`products-carousel-${componentData.position}`}>
          <Row>
            <Col lg={{ offset: 1, span: 10 }}>
              <FeaturedProducts
                brand={componentData.products_carrousel_brand}
              />
            </Col>
          </Row>
        </Container>
      );
    }

    return null;
  }

  renderContent() {
    const { homeData, isLoading } = this.state;
    
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    }
    
    if (homeData) {
      return (
        <>
          {homeData
            .sort((a, b) => parseInt(a.position) - parseInt(b.position))
            .map(item => this.mapDataToComponent(item))}
          <Footer />
        </>
      );
    }
    
    return (
      <Container fluid>
        <Row>
          <Col className="text-center py-5">
            <h3>No se pudieron cargar los datos. Intente nuevamente más tarde.</h3>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    ReactGA.pageview(this.props.location.pathname);
    return (
      <Container fluid style={{ backgroundColor: "#1f1f1f", color: "#e0e0e0", minHeight: "100vh" }}>
        <Loginbar name="Alberto Ayala Capetillo" customer_id="442424" />
        <Navigation auth={this.props.auth} />
        {PaymentMethods}
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingSonoritmo);