import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginbar from '../../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import KartList from '../../../shared/kartList/KartList';
import Loader from '../../../shared/loader/loader';
import Footer from '../../../shared/footer/footer';
import { getProfile, getRewardsConfigurations } from '../../../redux/actions';
import ReactGA from 'react-ga';

class Kart extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      isLoading: false,
    }
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidMount(){
    this.props.getRewardsConfigurations();
  }

  render() {
    return (
        <Container fluid>
            <Loginbar name="Alberto Ayala Capetillo" customer_id="442424"/>
            <Navigation category={this.state.selectedCategory} auth={this.props.auth}/>
            {!this.state.isLoading ? (
              <KartList kart={this.props.kart} auth={this.props.auth} getProfile={this.props.getProfile}>
              </KartList>):
              (<Loader/>)
            }
            <Footer/> 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    kart: state.global.kart,
  }
}
  
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  getRewardsConfigurations: () => dispatch(getRewardsConfigurations()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Kart);
