import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Row, Col, Card, ListGroup, Button, Modal, Badge } from 'react-bootstrap';
import Navigation from '../../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../../shared/footer/footer';
import ReactGA from 'react-ga';
import { getUserOrders, openChatAndSendMessage } from '../../../redux/actions';
import { dateToHumanFormat, mapStoreCodeToAddress } from '../../../utils/format';

const tranlateOrderState = (state) => {
  switch (state) {
    case "created":
      return "Preparando productos"
    case "in_progress":
      return "En camino"
    case "finished":
      return "Entregada"
    case "canceled":
      return "Cancelada"
    default:
      return "Estado desconocido"
  }
}

const getOrderStateColor = (state) => {
  switch (state) {
    case "created":
      return "#4e95f4"
    case "in_progress":
      return "#39b54a"
    case "finished":
      return "#8a8a8a"
    case "canceled":
      return "#e74c3c"
    default:
      return "#adadad"
  }
}

const getOrderStateBackground = (state) => {
  switch (state) {
    case "created":
      return "rgba(78, 149, 244, 0.15)"
    case "in_progress":
      return "rgba(57, 181, 74, 0.15)"
    case "finished":
      return "rgba(138, 138, 138, 0.15)"
    case "canceled":
      return "rgba(231, 76, 60, 0.15)"
    default:
      return "rgba(173, 173, 173, 0.15)"
  }
}

const OrderState = ({state}) => (
  <Badge 
    pill
    style={{
      backgroundColor: getOrderStateBackground(state),
      color: getOrderStateColor(state),
      padding: "8px 12px",
      fontWeight: "600",
      fontSize: "0.85rem"
    }}
  >
    <i className="fa fa-circle" style={{fontSize: "8px", marginRight: "6px"}}></i> 
    {tranlateOrderState(state)}
  </Badge>
)

const OrderCard = ({id, createdAt, updatedAt, state, items, handleTrackButton}) => (
  <Card className="order-card">
    <Card.Header className="order-header">
      <Row>
        <Col md={4} xs={12} className="order-id">
          <span className="order-label">Orden #</span>
          <span className="order-value">{id}</span>
        </Col>
        <Col md={4} xs={12} className="order-dates">
          <div>
            <span className="date-label">Creada:</span>
            <span className="date-value">{dateToHumanFormat(createdAt, "a las")}</span>
          </div>
          {updatedAt && (
            <div>
              <span className="date-label">Actualizada:</span> 
              <span className="date-value">{dateToHumanFormat(updatedAt, "a las")}</span>
            </div>
          )}
        </Col>
        <Col md={4} xs={12} className="order-status">
          <OrderState state={state} />
        </Col>
      </Row>
    </Card.Header>
    <Card.Body className="order-body">
      <Row>
        <Col md={8} xs={12}>
          <Card className="items-card">
            <ListGroup variant="flush">
              {items.map(item => (
                <ListGroup.Item key={item.model} className="order-item">
                  <Row className="align-items-center">
                    <Col xs={4} md={3} className="item-image">
                      <img alt="product" src={item.picture} />
                    </Col>
                    <Col xs={8} md={9} className="item-details">
                      <div className="item-description">{item.description}</div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
        <Col md={4} xs={12} className="order-actions">
          <Button 
            onClick={_ => handleTrackButton(id)} 
            disabled={!(state === "in_progress")} 
            variant={state === "in_progress" ? "success" : "secondary"}
            className="track-button"
            block
          >
            <i className="fa fa-map-marker mr-2"></i> Rastrear
          </Button>
          {/* Otros botones con state condicional */}
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

class Orders extends Component {

  constructor(props) {
    super(props);
    this.props = props;
  
    this.state = {
      isLoading: false,
      showModal: false,
      selectedOrder: null,
    }
    
    ReactGA.pageview(`${this.props.location.pathname}${this.props.location.search}`);
    this.handleTrackButton = this.handleTrackButton.bind(this);
    this.handleTrackOrderHelpButton = this.handleTrackOrderHelpButton.bind(this);
    this.toogleModal = this.toogleModal.bind(this);
  }

  componentDidMount() {
    this.props.getUserOrders(this.props.profile.id);
  }

  handleTrackButton(orderId) {
    const order = this.props.orders.find((order) => order.id === orderId);
    this.toogleModal(orderId, order);
  }

  handleTrackOrderHelpButton() {
    const message = `Hola!, ¿me puedes ayudar con mi pedido #${this.state.selectedOrder.id}?, tengo dudas acerca del envío`;
    const helpMessage = {
        id: new Date().getTime(),
        name: this.props.profile.first_name,
        timestamp: new Date(Date.now()-(new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
        type: "customer",
        message,
        metadata: {
            section: window.location.pathname,
            profilePicture: this.props.profile.picture
        },
    }
    this.props.openChatAndSendMessage(this.props.profile.id, helpMessage);
    this.toogleModal();
  }

  toogleModal(_, order){
    this.setState({...this.state, showModal: !this.state.showModal, selectedOrder: order})
  }

  getTrackingURL(){
    let url = "";
    
    if(this.state.selectedOrder && parseInt(this.state.selectedOrder.has_direct_tracking)) //eslint-disable-next-line
      url = this.state.selectedOrder.tracking_url_template.replace("${tracking_number}", this.state.selectedOrder.tracking_number);
    else if(this.state.selectedOrder && !parseInt(this.state.selectedOrder.has_direct_tracking)) 
      url = this.state.selectedOrder.tracking_url_template

    return url;
  }

  render() {
    const cards = this.props.orders.map(order => <OrderCard
        key={order.id}
        id={order.id}
        createdAt={order.created_at}
        updatedAt={order.updated_at}
        state={order.state}
        items={order.items}
        handleTrackButton={this.handleTrackButton}
      />);

    const url = this.getTrackingURL();

    return (
        <Container fluid className="orders-container">
            <style>
                {`
                    /* Dark mode styles */
                    .orders-container {
                        background-color: #1f1f1f;
                        color: #e0e0e0;
                        min-height: 100vh;
                    }

                    .orders-title {
                        color: #e0e0e0;
                        padding: 20px 0;
                        margin-bottom: 20px;
                        text-align: center;
                    }

                    .order-card {
                        background-color: #262626;
                        border: 1px solid #3a3a3a;
                        border-radius: 8px;
                        margin-bottom: 20px;
                        overflow: hidden;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
                    }

                    .order-header {
                        background-color: #2a2a2a;
                        border-bottom: 1px solid #3a3a3a;
                        padding: 15px;
                    }

                    .order-label, .date-label {
                        color: #a0a0a0;
                        margin-right: 5px;
                        font-size: 0.9rem;
                    }

                    .order-value, .date-value {
                        color: #e0e0e0;
                        font-weight: 600;
                    }

                    .order-id {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                    .order-dates {
                        font-size: 13px;
                        margin-bottom: 5px;
                    }

                    .order-status {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    .order-body {
                        padding: 15px;
                    }

                    .items-card {
                        background-color: #262626;
                        border: 1px solid #3a3a3a;
                    }

                    .order-item {
                        background-color: #2a2a2a;
                        border-bottom: 1px solid #3a3a3a;
                        padding: 10px;
                    }

                    .order-item:last-child {
                        border-bottom: none;
                    }

                    .item-image img {
                        max-height: 60px;
                        max-width: 100%;
                        object-fit: contain;
                    }

                    .item-description {
                        color: #e0e0e0;
                        font-size: 13px;
                    }

                    .order-actions {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    .track-button {
                        margin-bottom: 10px;
                    }

                    .track-button:disabled {
                        background-color: #3a3a3a;
                        border-color: #444;
                        opacity: 0.7;
                    }

                    .no-orders {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #262626;
                        border-radius: 8px;
                        padding: 40px;
                        text-align: center;
                        margin: 20px 0;
                    }

                    .no-orders i {
                        font-size: 48px;
                        color: #4e95f4;
                        margin-bottom: 15px;
                    }

                    /* Modal styling */
                    .modal-content {
                        background-color: #2a2a2a;
                        color: #e0e0e0;
                        border: 1px solid #3a3a3a;
                    }

                    .modal-header {
                        border-bottom: 1px solid #3a3a3a;
                    }

                    .modal-footer {
                        border-top: 1px solid #3a3a3a;
                    }

                    .close {
                        color: #e0e0e0;
                    }

                    @media (max-width: 768px) {
                        .order-status {
                            justify-content: flex-start;
                            margin-top: 10px;
                        }
                        
                        .order-actions {
                            margin-top: 15px;
                        }
                    }
                `}
            </style>

            <Navigation auth={this.props.auth} />
            
            <Row>
              <Container>
                <h2 className="orders-title">Mis Pedidos</h2>
              </Container>
            </Row>

            <Row className="h-100 products-content">
              <Container>
                {this.props.orders.length > 0 ? (
                  cards
                ) : !this.props.isLoading && (
                  <div className="no-orders">
                    <i className="fa fa-shopping-bag"></i>
                    <h4>Aún no has realizado ninguna compra</h4>
                    <p>Los pedidos que realices aparecerán aquí</p>
                  </div>
                )}
              </Container>
            </Row>
            
            <Footer />
            
            {this.state.selectedOrder && 
              <Modal show={this.state.showModal} onHide={this.toogleModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Rastrear mi paquete</Modal.Title>
                </Modal.Header>
                
                { this.state.selectedOrder.name !== 'PERSONAL' &&
                  <Modal.Body>
                    <p><span style={{color: "#a0a0a0"}}>Enviado por:</span> <b>{this.state.selectedOrder.name}</b></p>
                    <p><span style={{color: "#a0a0a0"}}>Número de guía:</span> <b>{this.state.selectedOrder.tracking_number}</b></p>
                    <hr style={{borderColor: "#3a3a3a"}} />
                    <p>Para rastrear tu paquete copia tu número de guía y ve a la página de rastreo de {this.state.selectedOrder.name}</p>
                  </Modal.Body>
                }
                
                { this.state.selectedOrder.name === 'PERSONAL' &&
                  <Modal.Body>
                    {parseInt(this.state.selectedOrder.in_store) === 1 &&
                    <>
                      <div style={{backgroundColor: "#1a2c42", padding: "10px", borderRadius: "5px", marginBottom: "15px"}}>
                        <i className="fa fa-store mr-2"></i> Te haremos la entrega personalmente, elegiste recoger en nuestra sucursal:
                      </div>
                      <p><b>{mapStoreCodeToAddress(this.state.selectedOrder.in_store_branch)}</b></p>
                    </>}
                    
                    {parseInt(this.state.selectedOrder.in_store) === 0 &&
                    <>
                      <div style={{backgroundColor: "#1a2c42", padding: "10px", borderRadius: "5px", marginBottom: "15px"}}>
                        <i className="fa fa-truck mr-2"></i> Te haremos la entrega personalmente ya que estás dentro de nuestra área de cobertura
                      </div>
                    </>}
                    
                    <p><span style={{color: "#a0a0a0"}}>Número de confirmación:</span> <b>{this.state.selectedOrder.tracking_number}</b></p>
                    <hr style={{borderColor: "#3a3a3a"}} />
                    <p>Recuerda proporcionarle tu número de confirmación a nuestro repartidor</p>
                  </Modal.Body>
                }
                
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.toogleModal}>
                    Cerrar
                  </Button>
                  
                  { this.state.selectedOrder.name !== 'PERSONAL' &&
                    <a rel="noopener noreferrer" href={url} target="_blank">
                      <Button variant="primary">
                        <i className="fa fa-external-link mr-1"></i> Ir a la página de {this.state.selectedOrder.name}
                      </Button>
                    </a>
                  }
                  
                  { this.state.selectedOrder.name === 'PERSONAL' &&
                    <Button variant="primary" onClick={this.handleTrackOrderHelpButton}>
                      <i className="fa fa-question-circle mr-1"></i> Necesito ayuda
                    </Button>
                  }
                </Modal.Footer>
              </Modal>
            } 
        </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    orders: state.global.orders,
    profile: state.global.profile,
  }
}
  
const mapDispatchToProps = dispatch => ({
    getUserOrders: (userId) => dispatch(getUserOrders(userId)),
    openChatAndSendMessage: (userId, message) => dispatch(openChatAndSendMessage(userId, message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);