import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { currencyFormat } from '../../utils/format';
import PurchaseModal from '../purchaseModal/PurchaseModal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { removeItemFromKart, updateItemKartAmount } from '../../redux/actions';
import Container from 'react-bootstrap/Container';
import history from '../../history';

class KartList extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.login = this.login.bind(this);
        this.state = {
            modalShow: false,
        };

        this.updateItemAmount = this.updateItemAmount.bind(this);
    }

    componentDidMount(){
        if(this.isAuthenticated()) {
            this.props.getProfile();
        }
    }

    login(){
        this.props.auth.login();
    }

    getRealPrice(product){
        return parseFloat(product.price_with_discount) <= parseFloat(product.price) ? parseFloat(product.price_with_discount) : parseFloat(product.price);
    }

    updateItemAmount(product, amount){
      amount = parseInt(amount)
      if(amount >= 1 && amount <= parseInt(product.stock)) {
        this.props.updateItemKartAmount("kart", product, amount)
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }

    buildKartItem(product) {
        const price = this.getRealPrice(product);
        
        return (
          <div className="cart-item" key={product.id}>
            <Row>
              <Col md={12} className="item-header d-none d-sm-block">
                <Row>
                  <Col md={5}>Producto</Col>
                  <Col md={3}>Precio Unitario</Col>
                  <Col md={2}></Col>
                  <Col md={2}>Cantidad</Col>
                </Row>
              </Col>
            </Row>
            <Row className="item-content">
              <Col md={5} xs={12} className="product-details">
                <div className="product-image-container">
                  <img alt="product" src={product.photos[0]} className="product-image"/>
                </div>
                <div className="product-info">
                  <div className="product-name">{product.model}</div>
                  <div className="product-stock">Máx. disponibles: {product.stock}</div>
                </div>
              </Col>
              <Col md={3} xs={6} className="product-price">
                <div className="price-label d-sm-block d-md-none">Precio:</div>
                <div className="price-value">${currencyFormat(price)}</div>
              </Col>
              <Col md={2} xs={6} className="remove-button-container">
                <Button 
                  variant="outline-danger" 
                  size="sm" 
                  className="remove-button"
                  onClick={(e) => this.props.removeItemFromKart("kart", product.id)}
                >
                  <i className="fa fa-trash"></i> Eliminar
                </Button>
              </Col>
              <Col md={2} xs={12} className="quantity-container">
                <div className="quantity-label d-sm-block d-md-none">Cantidad:</div>
                <InputGroup className="quantity-control">
                  <Button 
                    className="quantity-btn"
                    onClick={() => this.props.updateItemKartAmount("kart", product, Math.max(1, product.amount - 1))}
                    disabled={product.amount <= 1}
                  >
                    <i className="fa fa-minus"></i>
                  </Button>
                  <FormControl
                    className="quantity-input"
                    id={product.id}
                    type="number"
                    min={1}
                    max={product.stock}
                    value={product.amount}
                    onChange={(e) => {
                      const val = parseInt(e.target.value);
                      if (val >= 1 && val <= parseInt(product.stock)) {
                        this.props.updateItemKartAmount("kart", product, val);
                      }
                    }}
                  />
                  <Button 
                    className="quantity-btn"
                    onClick={() => this.props.updateItemKartAmount("kart", product, Math.min(product.stock, product.amount + 1))}
                    disabled={product.amount >= product.stock}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </div>
        );
    }

    getKartItems() {
        return this.props.kart.sort((a,b)=> b.id - a.id).map(product => this.buildKartItem(product));
    }

    getSubtotal() {
        return this.props.kart.reduce((prev,next) => prev + next.amount * this.getRealPrice(next), 0);
    }

    getTotal() {
        return this.props.kart.reduce((prev,next) => prev + next.amount * this.getRealPrice(next), 0);
    }

    isAuthenticated(){
        return this.props.auth.isAuthenticated();
    }

    canHaveInstallmentsWithoutInterest() {
        return this.props.kart.reduce((prev,next) => prev && next.hasInstallments, true)
    }

    goToCheckout() {
        history.push("/checkout");
    }

    getProductsCantHaveInstallmentsWithoutInterest() {
        return this.props.kart.filter(item => !item.hasInstallments).map(
            it => <li key={it.id}>{it.model}</li>
        )
    }
    
    render() {
        let modalClose = () => this.setState({ ...this.state, modalShow: false });
        
        let showModal = () => {
            if(this.props.profile.id) {
                this.setState({ ...this.state, modalShow: true });
            } else {
                console.error("COULDN'T GET USER PROFILE");
            }
        };
        
        const items = this.getKartItems();
        const subtotal = this.getSubtotal();
        const total = this.getTotal();
        const canHaveInstallmentsWithoutInterest = this.canHaveInstallmentsWithoutInterest();
        const productsCantHaveInstallmentsWithoutInterest = this.getProductsCantHaveInstallmentsWithoutInterest();

        return (
            <Container className="cart-container" fluid>
                <style>
                {`
                    :root {
                        --primary-color: #3584e4;
                        --primary-hover: #4a93eb;
                        --danger-color: #e74c3c;
                        --danger-hover: #c0392b;
                        --success-color: #2ecc71;
                        --warning-color: #f39c12;
                        --text-color: #e0e0e0;
                        --text-muted: #a0a0a0;
                        --bg-color: #1f1f1f;
                        --bg-secondary: #262626;
                        --bg-tertiary: #2a2a2a;
                        --border-color: #333;
                        --border-hover: #444;
                        --item-radius: 12px;
                    }
                    
                    .cart-container {
                        background-color: var(--bg-color);
                        color: var(--text-color);
                        padding: 20px 0;
                        min-height: 100vh;
                    }
                    
                    .cart-item {
                        background-color: var(--bg-secondary);
                        border-radius: var(--item-radius);
                        margin-bottom: 16px;
                        overflow: hidden;
                        border: 1px solid var(--border-color);
                        transition: all 0.2s ease;
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    }
                    
                    .cart-item:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                        border-color: var(--border-hover);
                    }
                    
                    .item-header {
                        background-color: var(--bg-tertiary);
                        padding: 12px 16px;
                        font-weight: 500;
                        color: var(--text-muted);
                        border-bottom: 1px solid var(--border-color);
                    }
                    
                    .item-content {
                        padding: 16px;
                    }
                    
                    .product-details {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                    }
                    
                    .product-image-container {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg-tertiary);
                        border-radius: 8px;
                        overflow: hidden;
                        margin-right: 16px;
                    }
                    
                    .product-image {
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                    }
                    
                    .product-info {
                        flex: 1;
                    }
                    
                    .product-name {
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                    
                    .product-stock {
                        font-size: 0.9rem;
                        color: var(--text-muted);
                    }
                    
                    .product-price {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    
                    .price-label {
                        font-size: 0.9rem;
                        color: var(--text-muted);
                        margin-bottom: 4px;
                    }
                    
                    .price-value {
                        font-size: 1.1rem;
                        font-weight: 600;
                    }
                    
                    .remove-button-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    .remove-button {
                        color: var(--danger-color);
                        border-color: var(--danger-color);
                        background-color: transparent;
                        transition: all 0.2s ease;
                    }
                    
                    .remove-button:hover {
                        background-color: var(--danger-color);
                        border-color: var(--danger-color);
                        color: white;
                    }
                    
                    .quantity-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        margin-top: 10px;
                    }
                    
                    .quantity-label {
                        font-size: 0.9rem;
                        color: var(--text-muted);
                        margin-bottom: 4px;
                    }
                    
                    .quantity-control {
                        max-width: 120px;
                    }
                    
                    .quantity-input {
                        background-color: var(--bg-tertiary);
                        border-color: var(--border-color);
                        color: var(--text-color);
                        text-align: center;
                    }
                    
                    .quantity-input:focus {
                        background-color: var(--bg-tertiary);
                        border-color: var(--primary-color);
                        color: var(--text-color);
                        box-shadow: 0 0 0 0.25rem rgba(53, 132, 228, 0.25);
                    }
                    
                    .quantity-btn {
                        background-color: var(--bg-tertiary) !important;
                        color: var(--text-color) !important;
                        border-color: var(--border-color) !important;
                        padding: 0.375rem 0.75rem;
                    }
                    
                    .quantity-btn:hover:not(:disabled) {
                        background-color: var(--primary-color) !important;
                        border-color: var(--primary-color) !important;
                        color: white !important;
                    }
                    
                    .quantity-btn:disabled {
                        background-color: #222 !important;
                        color: #666 !important;
                        border-color: #444 !important;
                    }
                    
                    /* Hide default number input arrows */
                    input[type=number]::-webkit-inner-spin-button, 
                    input[type=number]::-webkit-outer-spin-button { 
                        -webkit-appearance: none; 
                        margin: 0; 
                    }
                    
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    
                    .purchase-summary {
                        background-color: var(--bg-secondary);
                        border-radius: var(--item-radius);
                        padding: 20px;
                        height: fit-content;
                        border: 1px solid var(--border-color);
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    }
                    
                    .purchase-summary h4 {
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid var(--border-color);
                        color: white;
                        font-weight: 600;
                    }
                    
                    .summary-row {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        border-bottom: 1px solid var(--border-color);
                    }
                    
                    .summary-row:last-of-type {
                        border-bottom: none;
                    }
                    
                    .summary-label {
                        color: var(--text-muted);
                    }
                    
                    .summary-value {
                        font-weight: 600;
                    }
                    
                    .summary-total {
                        font-size: 1.2rem;
                        color: white;
                    }
                    
                    .installments-info {
                        background-color: rgba(46, 204, 113, 0.1);
                        border-radius: 8px;
                        padding: 12px;
                        margin: 16px 0;
                        border: 1px solid rgba(46, 204, 113, 0.2);
                    }
                    
                    .installments-info .title {
                        color: var(--success-color);
                        font-weight: 500;
                        margin-bottom: 8px;
                    }
                    
                    .installments-info .value {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                    
                    .installments-warning {
                        background-color: rgba(243, 156, 18, 0.1);
                        border-radius: 8px;
                        padding: 12px;
                        margin: 16px 0;
                        border: 1px solid rgba(243, 156, 18, 0.2);
                        color: var(--warning-color);
                    }
                    
                    .installments-warning ul {
                        margin-top: 8px;
                        padding-left: 20px;
                    }
                    
                    button.checkout-button {
                        background-color: var(--primary-color) !important;
                        border-color: var(--primary-color) !important;
                        color: white !important;
                        font-weight: 500;
                        padding: 10px 20px;
                        width: 100%;
                        margin: 16px 0;
                        border-radius: 8px;
                        transition: all 0.2s ease;
                    }
                    
                    button.checkout-button:hover {
                        background-color: var(--primary-hover) !important;
                        border-color: var(--primary-hover) !important;
                        transform: translateY(-2px);
                    }
                    
                    .payment-methods {
                        background-color: var(--bg-tertiary);
                        border-radius: 8px;
                        padding: 12px;
                        text-align: center;
                        margin: 16px 0;
                    }
                    
                    .payment-methods img {
                        max-width: 100%;
                    }
                    
                    .disclaimer {
                        font-size: 0.8rem;
                        color: var(--text-muted);
                        text-align: center;
                        margin-top: 10px;
                    }
                    
                    .empty-cart {
                        background-color: var(--bg-secondary);
                        border-radius: var(--item-radius);
                        padding: 40px 20px;
                        text-align: center;
                        margin: 30px 0;
                    }
                    
                    .empty-cart i {
                        font-size: 48px;
                        color: var(--text-muted);
                        margin-bottom: 16px;
                    }
                    
                    .empty-cart h4 {
                        margin-bottom: 8px;
                        color: white;
                    }
                    
                    .empty-cart p {
                        color: var(--text-muted);
                    }
                    
                    button.continue-shopping {
                        background-color: var(--primary-color) !important;
                        border-color: var(--primary-color) !important;
                        margin-top: 16px;
                        color: white !important;
                    }
                    
                    button.continue-shopping:hover {
                        background-color: var(--primary-hover) !important;
                        border-color: var(--primary-hover) !important;
                        color: white !important;
                    }
                    
                    button.btn-primary {
                        background-color: var(--primary-color) !important;
                        border-color: var(--primary-color) !important;
                        color: white !important;
                    }
                    
                    button.btn-primary:hover {
                        background-color: var(--primary-hover) !important;
                        border-color: var(--primary-hover) !important;
                    }
                    
                    @media (max-width: 768px) {
                        .purchase-summary {
                            margin-top: 20px;
                        }
                        
                        .product-details {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        
                        .product-image-container {
                            width: 100%;
                            height: 150px;
                            margin-right: 0;
                            margin-bottom: 16px;
                        }
                        
                        .remove-button-container {
                            justify-content: flex-start;
                        }
                    }
                `}
                </style>
                
                <div className="cart-content">
                    <Container>
                        <h2 className="mb-4">Mi Carrito</h2>
                        
                        {items.length > 0 ? (
                            <Row>
                                <Col lg={8} md={7}>
                                    {items}
                                </Col>
                                <Col lg={4} md={5}>
                                    <div className="purchase-summary">
                                        <h4 className="text-center">Resumen de compra</h4>
                                        
                                        <div className="summary-row">
                                            <div className="summary-label">Subtotal</div>
                                            <div className="summary-value">${currencyFormat(subtotal)}</div>
                                        </div>
                                        
                                        <div className="summary-row">
                                            <div className="summary-label summary-total">Total</div>
                                            <div className="summary-value summary-total">${currencyFormat(total)}</div>
                                        </div>
                                        
                                        {canHaveInstallmentsWithoutInterest && (
                                            <div className="installments-info">
                                                <div className="title">
                                                    <i className="fa fa-check-circle"></i> 12 meses sin intereses
                                                </div>
                                                <div className="value">${currencyFormat(total/12)}/mes</div>
                                            </div>
                                        )}
                                        
                                        {!canHaveInstallmentsWithoutInterest && this.props.kart.length > 1 && (
                                            <div className="installments-warning">
                                                <i className="fa fa-exclamation-circle"></i> Los siguientes productos no aplican para meses sin intereses:
                                                <ul>
                                                    {productsCantHaveInstallmentsWithoutInterest}
                                                </ul>
                                                Para obtener meses sin intereses puede removerlos de su carrito y comprarlos por separado.
                                            </div>
                                        )}
                                        
                                        {!this.isAuthenticated() ? (
                                            <Button className="checkout-button" onClick={this.login}>
                                                <i className="fa fa-lock mr-2"></i> Iniciar sesión para comprar
                                            </Button>
                                        ) : (
                                            <Button className="checkout-button" onClick={this.goToCheckout}>
                                                <i className="fa fa-shopping-cart mr-2"></i> Proceder al pago
                                            </Button>
                                        )}
                                        
                                        <div className="payment-methods">
                                            <img 
                                                className="full-max-width" 
                                                src="https://cdn.shopify.com/s/files/1/1788/1479/files/MP_3672a188-0e3c-4955-9cfb-8493fd057dc5.jpg?v=1488743602" 
                                                title="MercadoPago - Medios de pago" 
                                                alt="MercadoPago - Medios de pago"
                                            />
                                        </div>
                                        
                                        <div className="disclaimer">
                                            La disponibilidad de los productos está sujeta a su existencia
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <div className="empty-cart">
                                        <i className="fa fa-shopping-cart"></i>
                                        <h4>Tu carrito está vacío</h4>
                                        <p>Aún no has agregado ningún artículo a tu carrito</p>
                                        <Button className="continue-shopping" href="/">
                                            <i className="fa fa-arrow-left mr-2"></i> Continuar comprando
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
                
                {this.props.profile.id && (
                    <PurchaseModal 
                        canHaveInstallmentsWithoutInterest={canHaveInstallmentsWithoutInterest} 
                        total={total} 
                        show={this.state.modalShow} 
                        onHide={modalClose}
                    />
                )}
            </Container>
        );
    }
}

const mapStateToProps = function(state) {
    return {
      profile: state.global.profile,
      kart: state.global.kart || []
    }
}
    
const mapDispatchToProps = dispatch => ({
  removeItemFromKart: (kartName, itemId) => dispatch(removeItemFromKart(kartName, itemId)),
  updateItemKartAmount: (kartName, item, amount) => dispatch(updateItemKartAmount(kartName, item, amount))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KartList);