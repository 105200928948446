import React, { Component } from 'react';
import { connect } from 'react-redux';
import { currencyFormat } from '../../../utils/format';
import { Link } from 'react-router-dom';
import { createOrder } from '../../../redux/actions';

// Bootstrap Components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';

const SecurityText = styled.span`
  color: #81c784;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
  text-align: center;
  
  i {
    margin-right: 0.35rem;
  }
`;

class PurchaseWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      availablePoints: 0,
      delivery: false,
      store: false,
      validStep: false,
      pickInStore: {
        name: "",
        phone: "",
        store: "",
      },
      shipping: {
        name: "",
        address: "",
        district: "",
        city: "",
        state: "",
        zipCode: "",
        phone: "",
      },
      paymentMethod: "mercadopago",
      kart: this.props.kart || [],
      email: this.props.profile ? this.props.profile.email : '',
      canHaveInstallmentsWithoutInterest: this.props.canHaveInstallmentsWithoutInterest || false,
      isLoading: false,
      orderCompleted: false,
      orderError: null
    };

    this.selectShippingMethod = this.selectShippingMethod.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.handleInStoreFormChange = this.handleInStoreFormChange.bind(this);
    this.handleShippingFormChange = this.handleShippingFormChange.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.handleOrderCreation = this.handleOrderCreation.bind(this);
  }


  calculateInstallmentsAvailability() {
    return this.props.kart && this.props.kart.length > 0 
      ? this.props.kart.every(item => item.hasInstallments === 1 || item.hasInstallments === true)
      : false;
  }


  componentDidUpdate(prevProps) {
    if (prevProps.kart !== this.props.kart || prevProps.canHaveInstallmentsWithoutInterest !== this.props.canHaveInstallmentsWithoutInterest) {
      this.setState({
        kart: this.props.kart,
        canHaveInstallmentsWithoutInterest: this.props.canHaveInstallmentsWithoutInterest || this.calculateInstallmentsAvailability()
      });
    }
  }

  getCanHaveInstallmentsWithoutInterest() {
    return this.props.canHaveInstallmentsWithoutInterest || 
      this.state.kart.reduce((prev, next) => prev && next.hasInstallments, true);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.profile && this.props.profile.id) {
      // Pre-fill shipping data if user has previous orders
      if (this.props.profile.shippingInfo) {
        this.setState({ shipping: { ...this.props.profile.shippingInfo } });
      }
    }
  }

  validateStep(step, state) {
    if (step === 0) return state.delivery || state.store;
    if (step === 1 && state.store) {
      return state.pickInStore.name.length >= 4 && 
             state.pickInStore.phone.length >= 8 && 
             state.pickInStore.store.length > 0;
    }
    if (step === 1 && state.delivery) {
      return state.shipping.name.length >= 4 &&
             state.shipping.address.length >= 4 &&
             state.shipping.district.length >= 4 &&
             state.shipping.city.length >= 4 &&
             state.shipping.state.length >= 4 &&
             state.shipping.zipCode.length >= 5 &&
             state.shipping.phone.length >= 8;
    }
    if (step === 2) {
      return true; // Payment method step is always valid once an option is selected
    }
    return false;
  }

  nextStep() {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state, 
      currentStep: this.state.currentStep + 1, 
      validStep: false, 
      isLoading: false
    });
  }

  previousStep() {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state, 
      currentStep: this.state.currentStep - 1, 
      validStep: true
    });
  }

  selectShippingMethod(e) {
    let newState = {...this.state};
    
    if (e.target.id === "delivery" && !this.state.delivery) {
      newState = {...newState, delivery: !this.state.delivery, store: false};
    } else if (e.target.id === "store" && !this.state.store) {
      newState = {...newState, delivery: false, store: !this.state.store};
    } else {
      newState = {...newState, delivery: false, store: false};
    }
    
    newState.validStep = this.validateStep(newState.currentStep, newState);
    this.setState(newState);
  }

  handleShippingFormChange(e) {
    const { shipping } = this.state;
    
    shipping[e.target.id] = e.target.value;
    
    const newState = {...this.state, shipping};
    newState.validStep = this.validateStep(newState.currentStep, newState);
    
    this.setState(newState);
  }

  handleInStoreFormChange(e) {
    const { pickInStore } = this.state;
    
    if (e.target.name === "store") pickInStore.store = e.target.id;
    else pickInStore[e.target.id] = e.target.value;
    
    const newState = {...this.state, pickInStore: pickInStore};
    newState.validStep = this.validateStep(newState.currentStep, newState);
    
    this.setState(newState);
  }

  handlePaymentMethodChange(e) {
    const newState = {...this.state};
    newState.paymentMethod = e.target.id;
    newState.validStep = this.validateStep(newState.currentStep, newState);
    
    this.setState(newState);
  }

  handleOrderCreation() {
    // Asegúrate de que canHaveInstallmentsWithoutInterest esté actualizado
    const canHaveInstallments = this.calculateInstallmentsAvailability();
    
    this.setState({ 
      ...this.state,
      canHaveInstallmentsWithoutInterest: canHaveInstallments,
      isLoading: true 
    }, () => {
      let orderPayload = JSON.parse(JSON.stringify(this.state));
      orderPayload.kart = orderPayload.kart.map(item => {
        delete item.description;
        return item;
      });
      
      // Call the createOrder action
      this.props.createOrder(orderPayload);
      
      // Normally you would handle this in componentDidUpdate or using redux effects
      // For this example, we'll simulate success after a delay
      setTimeout(() => {
        this.setState({
          ...this.state, 
          isLoading: false, 
          orderCompleted: true,
          currentStep: 3
        });
      }, 2000);
    });
  }
  
  getProgressPercentage() {
    const { currentStep } = this.state;
    return (currentStep / 3) * 100;
  }

  getTotal() {
    return this.state.kart.reduce((prev, next) => 
      prev + next.amount * this.getRealPrice(next), 0
    );
  }

  getRealPrice(product) {
    return parseFloat(product.price_with_discount) <= parseFloat(product.price) ? 
      parseFloat(product.price_with_discount) : parseFloat(product.price);
  }

  canHaveInstallmentsWithoutInterest() {
    return this.state.kart.reduce((prev, next) => prev && next.hasInstallments, true);
  }

  renderShippingMethodStep() {
    return (
      <div className="step-container">
        <Card className="step-card">
          <Card.Header>
            <h5 className="step-title">
              <span className="step-number">1</span>
              <span>Método de Envío</span>
            </h5>
          </Card.Header>
          <Card.Body className="shipping-method-options">
            <Row>
              <Col lg={6} className="mb-3">
                <div 
                  className={`shipping-option ${this.state.delivery ? 'active' : ''}`}
                  onClick={() => this.selectShippingMethod({target: {id: 'delivery'}})}
                >
                  <div className="option-icon">
                    <i className="fa fa-truck"></i>
                  </div>
                  <div className="option-content">
                    <h5>Envío a Domicilio</h5>
                    <p>Recibe tu pedido en la comodidad de tu hogar</p>
                    <div className="shipping-details">
                      <span>Tiempo estimado: 3-5 días hábiles</span>
                    </div>
                    <Form.Check 
                      custom="true"
                      type="radio"
                      id="delivery"
                      label=""
                      checked={this.state.delivery}
                      onChange={this.selectShippingMethod}
                      className="shipping-radio"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} className="mb-3">
                <div 
                  className={`shipping-option ${this.state.store ? 'active' : ''}`}
                  onClick={() => this.selectShippingMethod({target: {id: 'store'}})}
                >
                  <div className="option-icon">
                    <i className="fa fa-map-marker"></i>
                  </div>
                  <div className="option-content">
                    <h5>Recoger en Tienda</h5>
                    <p>Visita nuestra tienda y recoge tu pedido</p>
                    <div className="shipping-details">
                      <span>Tiempo estimado: 24-48 horas</span>
                    </div>
                    <Form.Check 
                      custom="true"
                      type="radio"
                      id="store"
                      label=""
                      checked={this.state.store}
                      onChange={this.selectShippingMethod}
                      className="shipping-radio"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {(!this.state.delivery && !this.state.store) && (
              <Alert variant="info" className="mt-3">
                <i className="fa fa-info-circle me-2"></i>
                Selecciona un método de envío para continuar
              </Alert>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderDeliveryDetailsStep() {
    return (
      <div className="step-container">
        <Card className="step-card">
          <Card.Header>
            <h5 className="step-title">
              <span className="step-number">2</span>
              <span>Detalles de {this.state.store ? "Recogida" : "Envío"}</span>
            </h5>
          </Card.Header>
          <Card.Body>
            {this.state.store ? (
              <Form onChange={this.handleInStoreFormChange}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="name" className="mb-4">
                      <Form.Label>Nombre Completo</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Nombre de quien recogerá el pedido"
                        isInvalid={this.state.pickInStore.name.length > 0 && this.state.pickInStore.name.length < 4} 
                        value={this.state.pickInStore.name}
                        className="form-input"
                      />
                      {this.state.pickInStore.name.length > 0 && this.state.pickInStore.name.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Nombre no válido (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="phone" className="mb-4">
                      <Form.Label>Teléfono de Contacto</Form.Label>
                      <Form.Control 
                        type="tel" 
                        placeholder="55 5555 5555"
                        isInvalid={this.state.pickInStore.phone.length > 0 && this.state.pickInStore.phone.length < 8} 
                        value={this.state.pickInStore.phone}
                        className="form-input"
                      />
                      {this.state.pickInStore.phone.length > 0 && this.state.pickInStore.phone.length < 8 && (
                        <Form.Control.Feedback type="invalid">
                          Teléfono no válido (mínimo 8 dígitos)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                
                <div className="store-selection">
                  <h5 className="mb-3">Selecciona la Sucursal</h5>
                  <div 
                    className={`store-option ${this.state.pickInStore.store === "balbuena" ? 'active' : ''}`}
                    onClick={() => this.handleInStoreFormChange({target: {name: 'store', id: 'balbuena'}})}
                  >
                    <div className="store-radio">
                      <Form.Check 
                        type="radio" 
                        name="store" 
                        id="balbuena" 
                        checked={this.state.pickInStore.store === "balbuena"}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="store-info">
                      <h6>Sucursal San Juan de Aragón I Secc</h6>
                      <p>Av. 529 42, 1era sección, San Juan de Aragón I Secc, Gustavo A. Madero, 07969 Ciudad de México, CDMX</p>
                      <div className="store-details">
                        <span><i className="fa fa-clock me-2"></i>Horario: Lun-Vie 10am-6pm</span>
                        <span><i className="fa fa-phone me-2"></i>Tel: 55 3475 6429</span>
                      </div>
                      <Badge bg="success" className="store-badge">Disponible ahora</Badge>
                    </div>
                  </div>
                </div>
              </Form>
            ) : (
              <Form onChange={this.handleShippingFormChange}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="name" className="mb-4">
                      <Form.Label>Nombre Completo</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Nombre del destinatario"
                        isInvalid={this.state.shipping.name.length > 0 && this.state.shipping.name.length < 4} 
                        value={this.state.shipping.name}
                        className="form-input"
                      />
                      {this.state.shipping.name.length > 0 && this.state.shipping.name.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Nombre no válido (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="phone" className="mb-4">
                      <Form.Label>Teléfono de Contacto</Form.Label>
                      <Form.Control 
                        type="tel" 
                        placeholder="55 5555 5555"
                        isInvalid={this.state.shipping.phone.length > 0 && this.state.shipping.phone.length < 8} 
                        value={this.state.shipping.phone}
                        className="form-input"
                      />
                      {this.state.shipping.phone.length > 0 && this.state.shipping.phone.length < 8 && (
                        <Form.Control.Feedback type="invalid">
                          Teléfono no válido (mínimo 8 dígitos)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  <Col lg={12}>
                    <Form.Group controlId="address" className="mb-4">
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Calle y número"
                        isInvalid={this.state.shipping.address.length > 0 && this.state.shipping.address.length < 4} 
                        value={this.state.shipping.address}
                        className="form-input"
                      />
                      {this.state.shipping.address.length > 0 && this.state.shipping.address.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Dirección no válida (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="district" className="mb-4">
                      <Form.Label>Colonia</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Ej. Roma Norte"
                        isInvalid={this.state.shipping.district.length > 0 && this.state.shipping.district.length < 4} 
                        value={this.state.shipping.district}
                        className="form-input"
                      />
                      {this.state.shipping.district.length > 0 && this.state.shipping.district.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Colonia no válida (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="city" className="mb-4">
                      <Form.Label>Delegación / Municipio</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Ej. Cuauhtémoc"
                        isInvalid={this.state.shipping.city.length > 0 && this.state.shipping.city.length < 4} 
                        value={this.state.shipping.city}
                        className="form-input"
                      />
                      {this.state.shipping.city.length > 0 && this.state.shipping.city.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Delegación/Municipio no válido (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="state" className="mb-4">
                      <Form.Label>Estado / Ciudad</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Ej. Ciudad de México"
                        isInvalid={this.state.shipping.state.length > 0 && this.state.shipping.state.length < 4} 
                        value={this.state.shipping.state}
                        className="form-input"
                      />
                      {this.state.shipping.state.length > 0 && this.state.shipping.state.length < 4 && (
                        <Form.Control.Feedback type="invalid">
                          Estado/Ciudad no válido (mínimo 4 caracteres)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="zipCode" className="mb-4">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control 
                        type="text" 
                        placeholder="Ej. 03020"
                        isInvalid={this.state.shipping.zipCode.length > 0 && this.state.shipping.zipCode.length < 5} 
                        value={this.state.shipping.zipCode}
                        className="form-input"
                      />
                      {this.state.shipping.zipCode.length > 0 && this.state.shipping.zipCode.length < 5 && (
                        <Form.Control.Feedback type="invalid">
                          Código Postal no válido (5 dígitos)
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderPaymentStep() {
    const total = this.props.total || this.getTotal();
    
    return (
      <div className="step-container">
        <Card className="step-card">
          <Card.Header>
            <h5 className="step-title">
              <span className="step-number">3</span>
              <span>Método de Pago</span>
            </h5>
          </Card.Header>
          <Card.Body>
            <div className="payment-options">
              <div 
                className={`payment-option ${this.state.paymentMethod === "mercadopago" ? 'active' : ''}`}
                onClick={() => this.handlePaymentMethodChange({target: {id: 'mercadopago'}})}
              >
                <div className="payment-radio">
                  <Form.Check 
                    type="radio" 
                    name="paymentMethod" 
                    id="mercadopago" 
                    checked={this.state.paymentMethod === "mercadopago"}
                    onChange={this.handlePaymentMethodChange}
                  />
                </div>
                <div className="payment-info">
                  <div className="payment-logo">
                    <img 
                      src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.9/mercadopago/logo__large@2x.png" 
                      alt="MercadoPago" 
                    />
                  </div>
                  <div className="payment-details">
                    <h6>MercadoPago</h6>
                    <p>Paga con tarjeta, transferencia o en efectivo a través de MercadoPago</p>
                    <SecurityText>
                      <i className="fa fa-lock"></i>
                      Serás redirigido de forma segura a la página oficial de MercadoPago para completar tu pago
                    </SecurityText>
                      {(this.state.canHaveInstallmentsWithoutInterest || this.calculateInstallmentsAvailability()) && (
                        <Badge bg="success" className="payment-badge">
                          <i className="fa fa-check-circle me-1"></i>
                          12 meses sin intereses
                        </Badge>
                      )}
                  </div>
                </div>
              </div>
              {/*
              <div 
                className={`payment-option ${this.state.paymentMethod === "creditcard" ? 'active' : ''}`}
                onClick={() => this.handlePaymentMethodChange({target: {id: 'creditcard'}})}
              >
                <div className="payment-radio">
                  <Form.Check 
                    type="radio" 
                    name="paymentMethod" 
                    id="creditcard" 
                    checked={this.state.paymentMethod === "creditcard"}
                    onChange={this.handlePaymentMethodChange}
                  />
                </div>
                <div className="payment-info">
                  <div className="payment-logo">
                    <i className="fa fa-credit-card"></i>
                  </div>
                  <div className="payment-details">
                    <h6>Tarjeta de Crédito o Débito</h6>
                    <p>Paga directamente con tu tarjeta</p>
                    <div className="card-icons">
                      <i className="fa fa-cc-visa me-2"></i>
                      <i className="fa fa-cc-mastercard me-2"></i>
                      <i className="fa fa-cc-amex"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div 
                className={`payment-option ${this.state.paymentMethod === "banktransfer" ? 'active' : ''}`}
                onClick={() => this.handlePaymentMethodChange({target: {id: 'banktransfer'}})}
              >
                <div className="payment-radio">
                  <Form.Check 
                    type="radio" 
                    name="paymentMethod" 
                    id="banktransfer" 
                    checked={this.state.paymentMethod === "banktransfer"}
                    onChange={this.handlePaymentMethodChange}
                  />
                </div>
                <div className="payment-info">
                  <div className="payment-logo">
                    <i className="fa fa-university"></i>
                  </div>
                  <div className="payment-details">
                    <h6>Transferencia Bancaria</h6>
                    <p>Realiza una transferencia bancaria desde tu cuenta</p>
                  </div>
                </div>
              </div>
              */}
            </div>

            <div className="order-summary">
              <h5 className="summary-title">Resumen del Pedido</h5>
              <div className="summary-items">
                {this.state.kart.map(item => (
                  <div className="summary-item" key={item.id}>
                    <div className="item-img">
                      <img src={item.photos && item.photos.length > 0 ? item.photos[0] : '/placeholder.jpg'} alt={item.model} />
                    </div>
                    <div className="item-details">
                      <h6>{item.model}</h6>
                      <span className="item-quantity">Cantidad: {item.amount}</span>
                    </div>
                    <div className="item-price">
                      ${currencyFormat(this.getRealPrice(item) * item.amount)}
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="summary-totals">
                <div className="summary-row">
                  <span>Subtotal</span>
                  <span>${currencyFormat(total)}</span>
                </div>
                <div className="summary-row">
                  <span>Envío</span>
                  <span>{this.state.store ? 'Gratis' : '$150.00'}</span>
                </div>
                <div className="summary-row total">
                  <span>Total</span>
                  <span>${currencyFormat(total + (this.state.store ? 0 : 150))}</span>
                </div>
                {(this.state.canHaveInstallmentsWithoutInterest || this.calculateInstallmentsAvailability()) && (
                  <div className="installments-info">
                    <i className="fa fa-info-circle me-2"></i>
                    12 meses sin intereses de ${currencyFormat((total + (this.state.store ? 0 : 150))/12)}/mes
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderConfirmationStep() {
    const total = this.props.total || this.getTotal();
    return (
      <div className="step-container">
        <Card className="step-card confirmation-card">
          <Card.Body className="text-center">
            <div className="processing-icon">
              <i className="fa fa-sync-alt fa-spin"></i>
            </div>
            <h3 className="confirmation-title">Completando tu compra</h3>
            <p className="confirmation-message">
              Estamos redirigiendo a MercadoPago para finalizar tu proceso de pago de forma segura.
            </p>
            <div className="order-info">
              <div className="order-total">
                <span>Total a pagar:</span>
                <span>${currencyFormat(total + (this.state.store ? 0 : 150))}</span>
              </div>
            </div>
            <div className="redirect-info">
              <div className="security-badge">
                <i className="fa fa-lock"></i> Conexión segura
              </div>
              <p className="redirect-message">
                Por favor, no cierres esta ventana. Serás redirigido automáticamente en unos segundos...
              </p>
            </div>
            <div className="payment-methods">
              <small>Aceptamos:</small>
              <div className="payment-icons">
                <i className="fa fa-credit-card"></i>
                <i className="fa fa-university"></i>
                <i className="fa fa-money-bill"></i>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  render() {
    const { currentStep, isLoading, validStep } = this.state;
    const progressPercentage = this.getProgressPercentage();
    
    return (
      <Container fluid className="purchase-wizard-container">
        <Container>
          <div className="purchase-wizard-header">
            <h2 className="wizard-title">Completar Compra</h2>
            
            <div className="steps-progress">
              <div className="step-list">
                <div className="step-connector">
                  <div 
                    className="step-connector-progress" 
                    style={{ width: `${Math.max(0, (currentStep / 3) * 100)}%` }}
                  ></div>
                </div>
                
                <div className={`step-item ${currentStep >= 0 ? 'active' : ''} ${currentStep > 0 ? 'completed' : ''}`}>
                  <div className="step-dot">
                    {currentStep > 0 ? <i className="fa fa-check"></i> : 1}
                  </div>
                  <div className="step-label">Método de Envío</div>
                </div>
                
                <div className={`step-item ${currentStep >= 1 ? 'active' : ''} ${currentStep > 1 ? 'completed' : ''}`}>
                  <div className="step-dot">
                    {currentStep > 1 ? <i className="fa fa-check"></i> : 2}
                  </div>
                  <div className="step-label">Detalles de Envío</div>
                </div>
                
                <div className={`step-item ${currentStep >= 2 ? 'active' : ''} ${currentStep > 2 ? 'completed' : ''}`}>
                  <div className="step-dot">
                    {currentStep > 2 ? <i className="fa fa-check"></i> : 3}
                  </div>
                  <div className="step-label">Método de Pago</div>
                </div>
                
                <div className={`step-item ${currentStep >= 3 ? 'active' : ''}`}>
                  <div className="step-dot">
                    {currentStep > 3 ? <i className="fa fa-check"></i> : 4}
                  </div>
                  <div className="step-label">Confirmación</div>
                </div>
              </div>
              
              <ProgressBar 
                now={progressPercentage} 
                variant="primary" 
                animated={isLoading}
                className="progress-bar" 
              />
            </div>
          </div>
          
          {currentStep === 0 && this.renderShippingMethodStep()}
          {currentStep === 1 && this.renderDeliveryDetailsStep()}
          {currentStep === 2 && this.renderPaymentStep()}
          {currentStep === 3 && this.renderConfirmationStep()}
          
          {currentStep < 3 && (
            <div className="wizard-nav-buttons">
              {currentStep > 0 ? (
                <Button variant="outline-primary" onClick={this.previousStep}>
                  <i className="fa fa-arrow-left me-2"></i> Regresar
                </Button>
              ) : (
                <Link to="/cart">
                  <Button variant="outline-primary">
                    <i className="fa fa-arrow-left me-2"></i> Volver al Carrito
                  </Button>
                </Link>
              )}
              
              {currentStep < 2 ? (
                <Button 
                  variant="primary" 
                  onClick={this.nextStep} 
                  disabled={!validStep}
                >
                  Continuar <i className="fa fa-arrow-right ms-2"></i>
                </Button>
              ) : (
                <Button 
                  variant="primary" 
                  onClick={this.handleOrderCreation} 
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner 
                        as="span" 
                        animation="border" 
                        size="sm" 
                        role="status" 
                        aria-hidden="true" 
                        className="me-2"
                      />
                      Procesando...
                    </>
                  ) : (
                    <>Completar Pedido <i className="fa fa-check ms-2"></i></>
                  )}
                </Button>
              )}
            </div>
          )}
        </Container>
        <style>
        {`
          :root {
            --primary-color: #3584e4;
            --primary-hover: #4a93eb;
            --primary-light: rgba(53, 132, 228, 0.1);
            --secondary-color: #6c757d;
            --danger-color: #e74c3c;
            --success-color: #2ecc71;
            --warning-color: #f39c12;
            --info-color: #3498db;
            --text-color: #e0e0e0;
            --text-muted: #a0a0a0;
            --text-dark: #303030;
            --bg-color: #151515;
            --bg-secondary: #1e1e1e;
            --bg-tertiary: #252525;
            --bg-card: #202020;
            --border-color: #333;
            --border-hover: #444;
            --border-radius: 12px;
            --box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
            --transition: all 0.3s ease;
          }
          
          /* Base Styles */
          .purchase-wizard-container {
            background-color: var(--bg-color);
            color: var(--text-color);
            min-height: 100vh;
            padding: 40px 0;
            font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
          }
          
          .purchase-wizard-header {
            margin-bottom: 40px;
          }
          
          .wizard-title {
            color: white;
            font-weight: 600;
            margin-bottom: 16px;
          }
          
          .steps-progress {
            margin-bottom: 24px;
          }
          
          .step-list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            position: relative;
          }
          
          .step-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 2;
          }
          
          .step-dot {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--bg-tertiary);
            border: 2px solid var(--border-color);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            color: var(--text-muted);
            transition: var(--transition);
          }
          
          .step-item.active .step-dot {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            color: white;
            box-shadow: 0 0 0 4px rgba(53, 132, 228, 0.3);
          }
          
          .step-item.completed .step-dot {
            background-color: var(--success-color);
            border-color: var(--success-color);
            color: white;
          }
          
          .step-label {
            font-size: 0.875rem;
            color: var(--text-muted);
            text-align: center;
            transition: var(--transition);
          }
          
          .step-item.active .step-label {
            color: var(--primary-color);
            font-weight: 500;
          }
          
          .step-item.completed .step-label {
            color: var(--success-color);
          }
          
          .step-connector {
            position: absolute;
            top: 16px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: var(--border-color);
            z-index: 1;
          }
          
          .step-connector-progress {
            height: 100%;
            background-color: var(--success-color);
            transition: width 0.5s ease;
          }
          
          .progress-bar {
            height: 6px;
            border-radius: 3px;
            background-color: var(--bg-tertiary);
          }
          
          .progress-bar .progress-bar-animated {
            background-color: var(--primary-color);
            border-radius: 3px;
          }
          
          /* Card Styles */
          .step-container {
            margin-bottom: 32px;
          }
          
          .step-card {
            background-color: var(--bg-card);
            border: 1px solid var(--border-color);
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow);
            overflow: hidden;
            transition: var(--transition);
          }
          
          .step-card:hover {
            transform: translateY(-2px);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
          }
          
          .step-card .card-header {
            background-color: var(--bg-tertiary);
            border-bottom: 1px solid var(--border-color);
            padding: 16px 24px;
          }
          
          .step-title {
            display: flex;
            align-items: center;
            color: white;
            margin: 0;
          }
          
          .step-number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            background-color: var(--primary-color);
            color: white;
            border-radius: 50%;
            margin-right: 12px;
            font-size: 0.875rem;
            font-weight: 600;
          }
          
          .step-card .card-body {
            padding: 24px;
          }
          
          /* Form Styles */
          .form-input {
            background-color: var(--bg-tertiary) !important;
            border-color: var(--border-color) !important;
            color: var(--text-color) !important;
            border-radius: 8px;
            padding: 12px 16px;
            transition: var(--transition);
          }
          
          .form-input:focus {
            background-color: var(--bg-tertiary) !important;
            border-color: var(--primary-color) !important;
            box-shadow: 0 0 0 3px rgba(53, 132, 228, 0.3) !important;
            color: white !important;
          }
          
          .form-input.is-invalid {
            border-color: var(--danger-color) !important;
          }
          
          .form-input.is-invalid:focus {
            box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.3) !important;
          }
          
          .form-label {
            color: var(--text-color);
            font-weight: 500;
            margin-bottom: 8px;
          }
          
          .invalid-feedback {
            color: var(--danger-color);
            font-size: 0.8rem;
            margin-top: 4px;
          }
          
          /* Shipping Method Styles */
          .shipping-method-options {
            padding: 24px;
          }
          
          .shipping-option {
            display: flex;
            background-color: var(--bg-tertiary);
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 16px;
            margin-bottom: 16px;
            cursor: pointer;
            transition: var(--transition);
            height: 100%;
          }
          
          .shipping-option:hover {
            border-color: var(--primary-color);
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          }
          
          .shipping-option.active {
            border-color: var(--primary-color);
            background-color: var(--primary-light);
          }
          
          .option-icon {
            width: 48px;
            height: 48px;
            background-color: var(--bg-secondary);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            color: var(--primary-color);
            font-size: 1.25rem;
            flex-shrink: 0;
          }
          
          .option-content {
            flex: 1;
          }
          
          .option-content h5 {
            margin-bottom: 8px;
            color: white;
            font-weight: 500;
          }
          
          .option-content p {
            color: var(--text-muted);
            margin-bottom: 12px;
            font-size: 0.9rem;
          }
          
          .shipping-details {
            font-size: 0.85rem;
            color: var(--text-color);
            margin-bottom: 8px;
          }
          
          .shipping-radio {
            margin-top: 4px;
          }
          
          /* Store Selection Styles */
          .store-selection {
            margin-top: 24px;
          }
          
          .store-option {
            display: flex;
            background-color: var(--bg-tertiary);
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 16px;
            margin-bottom: 16px;
            cursor: pointer;
            transition: var(--transition);
          }
          
          .store-option:hover:not(.disabled) {
            border-color: var(--primary-color);
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          }
          
          .store-option.active {
            border-color: var(--primary-color);
            background-color: var(--primary-light);
          }
          
          .store-option.disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }
          
          .store-radio {
            padding-right: 16px;
            display: flex;
            align-items: center;
          }
          
          .store-info {
            flex: 1;
          }
          
          .store-info h6 {
            margin-bottom: 8px;
            color: white;
            font-weight: 500;
          }
          
          .store-info p {
            color: var(--text-muted);
            margin-bottom: 12px;
            font-size: 0.9rem;
          }
          
          .store-details {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-bottom: 12px;
            font-size: 0.85rem;
            color: var(--text-color);
          }
          
          .store-badge {
            font-size: 0.75rem;
            padding: 6px 10px;
            border-radius: 12px;
          }
          
          /* Payment Styles */
          .payment-options {
            margin-bottom: 32px;
          }
          
          .payment-option {
            display: flex;
            background-color: var(--bg-tertiary);
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 16px;
            margin-bottom: 16px;
            cursor: pointer;
            transition: var(--transition);
          }
          
          .payment-option:hover {
            border-color: var(--primary-color);
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          }
          
          .payment-option.active {
            border-color: var(--primary-color);
            background-color: var(--primary-light);
          }
          
          .payment-radio {
            padding-right: 16px;
            display: flex;
            align-items: center;
          }
          
          .payment-info {
            flex: 1;
            display: flex;
            align-items: center;
          }
          
          .payment-logo {
            width: 60px;
            height: 60px;
            background-color: var(--bg-secondary);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            overflow: hidden;
            flex-shrink: 0;
          }
          
          .payment-logo img {
            max-width: 80%;
            max-height: 80%;
            object-fit: contain;
          }
          
          .payment-logo i {
            font-size: 1.5rem;
            color: var(--primary-color);
          }
          
          .payment-details {
            flex: 1;
          }
          
          .payment-details h6 {
            margin-bottom: 8px;
            color: white;
            font-weight: 500;
          }
          
          .payment-details p {
            color: var(--text-muted);
            margin-bottom: 12px;
            font-size: 0.9rem;
          }
          
          .card-icons {
            font-size: 1.5rem;
            color: var(--text-muted);
          }
          
          .payment-badge {
            font-size: 0.75rem;
            padding: 6px 10px;
            border-radius: 12px;
            display: inline-flex;
            align-items: center;
          }
          
          /* Order Summary Styles */
          .order-summary {
            background-color: var(--bg-tertiary);
            border-radius: 12px;
            padding: 24px;
            margin-top: 32px;
          }
          
          .summary-title {
            margin-bottom: 16px;
            color: white;
            font-weight: 600;
            border-bottom: 1px solid var(--border-color);
            padding-bottom: 16px;
          }
          
          .summary-items {
            margin-bottom: 24px;
            max-height: 300px;
            overflow-y: auto;
            padding-right: 8px;
          }
          
          .summary-items::-webkit-scrollbar {
            width: 6px;
          }
          
          .summary-items::-webkit-scrollbar-track {
            background: var(--bg-secondary);
            border-radius: 3px;
          }
          
          .summary-items::-webkit-scrollbar-thumb {
            background: var(--border-color);
            border-radius: 3px;
          }
          
          .summary-items::-webkit-scrollbar-thumb:hover {
            background: var(--border-hover);
          }
          
          .summary-item {
            display: flex;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--border-color);
          }
          
          .summary-item:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }
          
          .item-img {
            width: 64px;
            height: 64px;
            border-radius: 8px;
            overflow: hidden;
            background-color: var(--bg-secondary);
            margin-right: 16px;
            flex-shrink: 0;
          }
          
          .item-img img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          
          .item-details {
            flex: 1;
          }
          
          .item-details h6 {
            margin-bottom: 4px;
            color: white;
            font-weight: 500;
          }
          
          .item-quantity {
            font-size: 0.85rem;
            color: var(--text-muted);
          }
          
          .item-price {
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
          }
          
          .summary-totals {
            border-top: 1px solid var(--border-color);
            padding-top: 16px;
          }
          
          .summary-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            color: var(--text-muted);
          }
          
          .summary-row.total {
            font-size: 1.25rem;
            font-weight: 600;
            color: white;
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid var(--border-color);
          }
          
          .installments-info {
            background-color: rgba(46, 204, 113, 0.1);
            border-radius: 8px;
            padding: 12px;
            margin-top: 16px;
            font-size: 0.9rem;
            color: var(--success-color);
            text-align: center;
          }
          
          /* Confirmation Page Styles */
          .confirmation-card {
            text-align: center;
            padding: 32px;
          }
          
          .confirmation-icon {
            font-size: 5rem;
            color: var(--success-color);
            margin-bottom: 24px;
          }
          
          .confirmation-title {
            color: white;
            font-weight: 600;
            margin-bottom: 16px;
          }
          
          .confirmation-message {
            color: var(--text-muted);
            font-size: 1.1rem;
            margin-bottom: 32px;
          }
          
          .order-info {
            background-color: var(--bg-tertiary);
            border-radius: 12px;
            padding: 24px;
            margin-bottom: 32px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          
          .order-info > div {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: 0 16px;
            flex: 1;
            min-width: 150px;
          }
          
          .order-info > div span:first-child {
            font-size: 0.85rem;
            color: var(--text-muted);
            margin-bottom: 4px;
          }
          
          .order-info > div span:last-child {
            font-size: 1.1rem;
            font-weight: 600;
            color: white;
          }
          
          .next-steps {
            margin-bottom: 32px;
          }
          
          .next-steps h5 {
            color: white;
            font-weight: 600;
            margin-bottom: 12px;
          }
          
          .next-steps p {
            color: var(--text-muted);
          }
          
          .confirmation-actions {
            display: flex;
            gap: 16px;
            justify-content: center;
            margin-top: 32px;
          }
          
          /* Button Styles */
          .purchase-wizard-container button {
            border-radius: 8px;
            padding: 10px 20px;
            font-weight: 500;
            transition: var(--transition);
          }
          
          .purchase-wizard-container button.btn-primary {
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
          }
          
          .purchase-wizard-container button.btn-primary:hover {
            background-color: var(--primary-hover) !important;
            border-color: var(--primary-hover) !important;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          
          .purchase-wizard-container button.btn-outline-primary {
            border-color: var(--primary-color) !important;
            color: var(--primary-color) !important;
            background-color: transparent !important;
          }
          
          .purchase-wizard-container button.btn-outline-primary:hover {
            background-color: var(--primary-light) !important;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          
          .purchase-wizard-container .wizard-nav-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
          }
          
          /* Responsive Styles */
          @media (max-width: 768px) {
            .purchase-wizard-container {
              padding: 20px 0;
            }
            
            .step-list {
              overflow-x: auto;
              padding-bottom: 16px;
            }
            
            .step-item {
              flex: 0 0 auto;
              min-width: 100px;
              margin-right: 16px;
            }
            
            .step-card .card-body {
              padding: 16px;
            }
            
            .payment-info {
              flex-direction: column;
              align-items: flex-start;
            }
            
            .payment-logo {
              margin-bottom: 16px;
            }
            
            .order-info {
              flex-direction: column;
            }
            
            .confirmation-actions {
              flex-direction: column;
            }
          }
        `}
        </style>
      </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    kart: state.global.kart,
    profile: state.global.profile,
    canHaveInstallmentsWithoutInterest: state.global.canHaveInstallmentsWithoutInterest
  }
}
    
  const mapDispatchToProps = dispatch => ({
    createOrder: (orderPayload) => dispatch(createOrder(orderPayload)),
  })
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PurchaseWizard);
  
  