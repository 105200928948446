import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import CreditPriceMonths from '../../shared/creditPriceMonths/CreditPriceMonths';
import { currencyFormat, urlFormat } from '../../utils/format';
import PremiumProductModal from '../premiumProductModal/PremiumProductModal';

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

class ProductCard extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            amount: 1,
            isHovered: false
        };
    }

    itemHasDiscount() {
        return this.props.price_with_discount 
            && parseFloat(this.props.price_with_discount) < parseFloat(this.props.price);
    }

    getFinalPrice() {
        return !this.itemHasDiscount() ? this.props.price : this.props.price_with_discount;
    }

    getItemType() {
        return !this.props.product.items ? 'item' : 'pack';
    }
    
    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }
    
    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }
    
    render() {
        let price = this.getFinalPrice();
        const productLink = `/${this.getItemType()}/${urlFormat(this.props.product.model)}/${this.props.product.id}`;
        const isOutOfStock = this.props.stock <= 0 && !this.props.product.items;
        const discountPercentage = this.itemHasDiscount() ? 
            Math.round(100 - (parseFloat(this.props.price_with_discount) * 100 / parseFloat(this.props.price))) : 0;
        
        return (
            <div className="product-card-wrapper">
                <style>
                    {`
                        .product-card-wrapper {
                            padding: 5px;
                        }
                        
                        .product-card {
                            background-color: #262626;
                            border-radius: 10px;
                            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
                            overflow: hidden;
                            transition: all 0.3s ease;
                            position: relative;
                            width: 250px; /* Fixed width */
                            height: 400px; /* Fixed height */
                            margin: 0 auto 10px;
                            border: 1px solid #333;
                        }
                        
                        .product-card:hover {
                            transform: translateY(-5px);
                            box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
                            border-color: #444;
                        }
                        
                        .product-image-wrapper {
                            position: relative;
                            width: 100%;
                            height: 154px; /* Fixed height */
                            background-color: #1a1a1a;
                            border-bottom: 1px solid #333;
                            display: flex;           /* Add flex display */
                            align-items: center;     /* Center vertically */
                            justify-content: center; /* Center horizontally */
                            overflow: hidden;        /* Prevent overflow */
                        }

                        .product-image {
                            max-width: 100%;         /* Instead of width: 100% */
                            min-height: 100%;        /* Instead of height: 100% */
                            object-fit: contain;     /* 'contain' works better than 'scale-down' in most cases */
                            padding: 10px;
                            transition: transform 0.4s ease;
                        }
                        
                        .product-card:hover .product-image {
                            transform: scale(1.05);
                        }
                        
                        .product-details {
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            height: calc(100% - 154px); /* Fixed height calculation based on image height */
                            color: #e0e0e0;
                        }
                        
                        .sold-units {
                            font-size: 10px;
                            color: #999;
                            height: 15px; /* Fixed height */
                            margin-bottom: 3px;
                        }
                        
                        .product-title {
                            font-size: 13px;
                            font-weight: 500;
                            color: #e0e0e0;
                            margin-bottom: 8px;
                            padding-bottom: 6px;
                            border-bottom: 1px solid #333;
                            height: 100px; /* Increased from 32px */
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        
                        .price-section {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 8px;
                            height: 24px; /* Slightly increased height */
                        }
                        
                        .final-price {
                            font-size: 16px;
                            font-weight: 700;
                            color: #fff;
                        }
                        
                        .original-price {
                            font-size: 11px;
                            text-decoration: line-through;
                            color: #999;
                            margin-left: 5px;
                        }
                        
                        .shipping-icon {
                            color: #28a745;
                        }
                        
                        .credit-info-container {
                            height: 20px; /* Fixed height */
                            margin-bottom: 8px;
                            font-size: 11px;
                            color: #999;
                        }
                        
                        .premium-container {
                            height: 20px; /* Fixed height */
                            margin-bottom: 8px;
                        }
                        
                        .details-link-container {
                            height: 25px; /* Fixed height */
                            margin-bottom: 5px;
                        }
                        
                        .details-link {
                            color: #8bb9f0;
                            text-decoration: none;
                            font-size: 13px;
                            font-weight: 500;
                            display: inline-block;
                            padding: 0;
                        }
                        
                        .details-link:hover {
                            color: #a3c9f8;
                            text-decoration: underline;
                        }
                        
                        .stock-info {
                            font-size: 11px;
                            color: #999;
                            height: 15px; /* Fixed height */
                            margin-bottom: 5px;
                        }
                        
                        .button-container {
                            margin-top: auto; /* Push to bottom */
                            padding-top: 8px;
                        }
                        
                        .cart-button {
                            background-color: #1a2c42;
                            border: none;
                            width: 100%;
                            padding: 8px 0;
                            border-radius: 6px;
                            color: white;
                            font-weight: 500;
                            transition: background-color 0.2s ease;
                            font-size: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .cart-button:hover {
                            background-color: #2c3e50;
                        }

                        .cart-button:disabled {
                            background-color: #2d3748;
                            opacity: 0.6;
                            cursor: not-allowed;
                        }
                        
                        .cart-icon {
                            margin-left: 8px;
                        }
                        
                        .discount-badge {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            background-color: #e63946;
                            color: white;
                            padding: 3px 8px;
                            border-radius: 4px;
                            font-size: 11px;
                            font-weight: bold;
                            z-index: 2;
                        }
                        
                        .unavailable-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.7);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 3;
                        }
                        
                        .unavailable-label {
                            background-color: rgba(220, 53, 69, 0.9);
                            color: white;
                            padding: 5px 10px;
                            border-radius: 4px;
                            font-weight: bold;
                            font-size: 14px;
                            transform: rotate(-15deg);
                            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
                            text-transform: uppercase;
                        }
                        
                        /* Ensure placeholder elements maintain height even when empty */
                        .spacer {
                            height: 20px;
                        }
                    `}
                </style>

                <div 
                    className="product-card"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {/* Discount Badge */}
                    {discountPercentage > 0 && (
                        <div className="discount-badge">{discountPercentage}% OFF</div>
                    )}
                    
                    {/* Out of Stock Overlay */}
                    {isOutOfStock && (
                        <div className="unavailable-overlay">
                            <div className="unavailable-label">Agotado</div>
                        </div>
                    )}
                    
                    {/* Product Image */}
                    <div className="product-image-wrapper">
                        <Nav.Link href={productLink}>
                            <img 
                                className="product-image" 
                                src={this.props.photos[0]} 
                                alt={this.props.model || "Producto"}
                            />
                        </Nav.Link>
                    </div>
                    
                    {/* Product Details */}
                    <div className="product-details">
                        {/* Sold Units - Fixed Height Container */}
                        <div className="sold-units">
                            {this.props.product.sold_units > 0 ? (
                                <>{this.props.product.sold_units} {this.props.product.sold_units === 1 ? 'Vendido' : 'Vendidos'}</>
                            ) : null}
                        </div>
                        
                        {/* Product Title - Fixed Height */}
                        <div className="product-title">
                            {this.props.product.title ? this.props.product.title + " - " : ""} 
                            {this.props.model}
                        </div>
                        
                        {/* Price Section - Fixed Height */}
                        <div className="price-section">
                            {!(price < 0.1 || this.props.product.items) ? (
                                <>
                                    <div className="price-container">
                                        <div className="final-price">${currencyFormat(price)}</div>
                                        
                                        {this.itemHasDiscount() && (
                                            <div className="original-price">${currencyFormat(this.props.price)}</div>
                                        )}
                                    </div>
                                    
                                    <div className="shipping-icon">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-shipping">Envío Gratis</Tooltip>}
                                        >
                                            <i className="fa fa-truck"></i>
                                        </OverlayTrigger>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        
                        {/* Credit Information - Fixed Height Container */}
                        <div className="credit-info-container">
                            {!(price < 0.1 || this.props.product.items) ? (
                                <CreditPriceMonths 
                                    price={price} 
                                    hasItems={this.props.product.items} 
                                    hasInstallments={this.props.product.hasInstallments}
                                />
                            ) : null}
                        </div>
                        
                        {/* Premium Modal - Fixed Height Container */}
                        <div className="premium-container">
                            {(price < 0.1 || this.props.product.items) ? (
                                <PremiumProductModal model={this.props.model} />
                            ) : null}
                        </div>
                        
                        {/* Details Link - Fixed Height Container */}
                        <div className="details-link-container">
                            <Nav.Link href={productLink} className="details-link">
                                Detalle
                            </Nav.Link>
                        </div>
                        
                        {/* Stock Information - Fixed Height */}
                        <div className="stock-info">
                            {!this.props.product.items ? (
                                <>Disponible: {this.props.stock}</>
                            ) : (
                                <>Disponibilidad inmediata</>
                            )}
                        </div>
                        
                        {/* Add to Cart Button - Always at Bottom */}
                        <div className="button-container">
                            {!(price < 0.1 || this.props.product.items) && !REACT_APP_INFORMATIVE_MODE ? (
                                <Button 
                                    className="cart-button"
                                    onClick={e => this.props.addToKartHandler("kart", {...this.props.product, amount: 1})}
                                    disabled={isOutOfStock}
                                >
                                    Agregar <i className="fa fa-cart-plus cart-icon"></i>
                                </Button>
                            ) : <div className="spacer"></div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductCard;