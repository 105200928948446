import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <>
      <style>
        {`
          :root {
            --primary-color: #3584e4;
            --primary-hover: #4a93eb;
            --text-color: #e0e0e0;
            --text-muted: #a0a0a0;
            --bg-color: #1f1f1f;
            --footer-bg: #171717;
            --footer-secondary: #121212;
            --border-color: rgba(255,255,255,0.1);
            --hover-bg: #2c2c2c;
            --item-radius: 8px;
            --transition: all 0.2s ease;
          }
          
          .footer-container {
            background-color: var(--footer-bg);
            color: var(--text-color);
            padding: 2.5rem 0 0 0;
            font-size: 14px;
          }
          
          .footer-section {
            margin-bottom: 2rem;
            text-align: center;
          }
          
          .footer-title {
            color: var(--text-color);
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 1.5rem;
            text-align: center;
            text-transform: uppercase;
          }
          
          .footer-line {
            width: 80px;
            height: 2px;
            background-color: var(--primary-color);
            margin: 0 auto 1.5rem;
            display: block;
          }
          
          .footer-content {
            color: var(--text-muted);
            line-height: 1.6;
          }
          
          .footer-content a {
            color: var(--text-muted);
            text-decoration: none;
            transition: var(--transition);
          }
          
          .footer-content a:hover {
            color: var(--primary-hover);
          }
          
          .social-icons {
            display: flex;
            gap: 1rem;
            margin-top: 0.5rem;
            justify-content: center;
          }
          
          .social-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--hover-bg);
            transition: var(--transition);
            border: 1px solid var(--border-color);
          }
          
          .social-icon:hover {
            background-color: var(--primary-color);
            transform: translateY(-3px);
          }
          
          .social-icon i {
            font-size: 20px;
            color: var(--text-color);
          }
          
          .copyright-bar {
            background-color: var(--footer-secondary);
            padding: 1rem 0;
            font-size: 12px;
            color: var(--text-muted);
            border-top: 1px solid var(--border-color);
            text-align: center;
          }
          
          .document-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: var(--item-radius);
            background-color: var(--hover-bg);
            margin: 0 0.5rem;
            transition: var(--transition);
            border: 1px solid var(--border-color);
          }
          
          .document-icon:hover {
            background-color: var(--primary-color);
            transform: translateY(-2px);
          }
        `}
      </style>

      <footer className="footer-container">
        <Container>
          <Row>
            <Col md={4} className="footer-section">
              <h3 className="footer-title">CONTÁCTANOS</h3>
              <div className="footer-line"></div>
              <div className="footer-content">
                Retorno 11 Calzada Ignacio Zaragoza #47 local B <br/>
                C.P 15900 Delegacion Venustiano Carranza<br/>
                Ciudad De Mexico<br/>
                Tel: 55 3475 6429
              </div>
            </Col>
            
            <Col md={4} className="footer-section">
              <h3 className="footer-title">AVISOS DE PRIVACIDAD</h3>
              <div className="footer-line"></div>
              <div className="footer-content">
                <a href="#" target="blank" className="document-icon">
                  <i className="fa fa-file-text-o"></i>
                </a>
                <a href="#" target="blank" className="document-icon">
                  <i className="fa fa-file-pdf-o"></i>
                </a>
              </div>
            </Col>
            
            <Col md={4} className="footer-section">
              <h3 className="footer-title">SÍGUENOS</h3>
              <div className="footer-line"></div>
              <div className="social-icons">
                <a href="https://www.facebook.com/bitstruments" target="blank" className="social-icon">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://twitter.com/bitstruments" target="blank" className="social-icon">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="https://www.instagram.com/bitstruments/" target="blank" className="social-icon">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        
        <div className="copyright-bar">
          <Container>
            <Row>
              <Col md={12}>
                © 2025 Bitstruments / Todos los derechos reservados D.R. ®
              </Col>
            </Row>
          </Container>
        </div>
        
        <div className="copyright-bar" style={{ borderTop: 'none', paddingTop: '0' }}>
          <Container>
            <Row>
              <Col md={12}>
                Powered by BusinessBay ©
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;