import React, { useState } from 'react';
import { Modal, Container, Row, Col, Badge } from 'react-bootstrap';
import styled from 'styled-components';

const PaymentMethodsModal = ({ children }) => {
  const [show, setShow] = useState(false);
  const [activeMethod, setActiveMethod] = useState(null);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const OpenModalTrigger = React.cloneElement(children, {
    onClick: handleShow,
  });

  // Componentes estilizados
  const StyledModal = styled(Modal)`
    .modal-content {
      background-color: #1a1a1a;
      border-radius: 16px;
      border: 1px solid #333;
      box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
    }
    
    .modal-header {
      border-bottom: 1px solid #333;
      padding: 20px 24px;
      background-color: #222;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    
    .modal-title {
      color: #e0e0e0;
      font-weight: 600;
      font-size: 1.3rem;
    }
    
    .modal-body {
      padding: 24px;
    }
    
    .btn-close {
      color: #e0e0e0;
      filter: invert(1) grayscale(100%) brightness(200%);
    }
  `;

  const PaymentMethodCard = styled.div`
    background-color: ${props => props.active ? '#262626' : '#222'};
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    border: 1px solid ${props => props.active ? '#444' : '#333'};
    cursor: pointer;
    box-shadow: ${props => props.active ? '0 8px 24px rgba(0, 0, 0, 0.25)' : 'none'};
    transform: ${props => props.active ? 'translateY(-3px)' : 'none'};
    
    &:hover {
      background-color: #262626;
      border-color: #444;
      transform: translateY(-3px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
    }
  `;

  const MethodTitle = styled.h5`
    color: #fff;
    margin-bottom: 8px;
    font-weight: 600;
  `;

  const MethodDescription = styled.p`
    color: #a0a0a0;
    font-size: 14px;
    margin-bottom: ${props => props.noMargin ? '0' : '16px'};
  `;

  const IconContainer = styled.div`
    background-color: rgba(53, 132, 228, 0.15);
    width: 60px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #4a93eb;
    font-size: 24px;
  `;

  const LogosContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 16px;
  `;

  const PaymentIcon = styled.div`
    background-color: #2a2a2a;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    height: 56px;
    width: ${props => props.large ? '110px' : '70px'};
    color: ${props => props.color || '#fff'};
    font-size: ${props => props.large ? '16px' : '14px'};
    font-weight: 600;
    letter-spacing: 0.5px;
    
    &:hover {
      background-color: #333;
      transform: translateY(-2px);
    }
    
    i {
      margin-right: ${props => props.text ? '8px' : '0'};
      font-size: ${props => props.large ? '20px' : '24px'};
    }
  `;

  const SubtitleText = styled.div`
    font-size: 13px;
    color: #a0a0a0;
    margin-bottom: 10px;
    margin-top: ${props => props.marginTop ? '16px' : '0'};
    font-weight: 500;
  `;

  const PromoBadge = styled(Badge)`
    background-color: rgba(53, 132, 228, 0.2);
    color: #4a93eb;
    font-weight: 500;
    font-size: 11px;
    padding: 5px 8px;
    border-radius: 6px;
    margin-left: 8px;
  `;

  const DetailContainer = styled.div`
    background-color: #262626;
    border-radius: 14px;
    padding: 20px;
    margin-top: 16px;
    border: 1px solid #333;
    display: ${props => props.active ? 'block' : 'none'};
    animation: fadeIn 0.3s ease;
    
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(10px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  return (
    <>
      {OpenModalTrigger}
      <StyledModal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Medios de pago
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* Mercado Pago */}
            <PaymentMethodCard 
              active={activeMethod === 'mercadopago'} 
              onClick={() => setActiveMethod(activeMethod === 'mercadopago' ? null : 'mercadopago')}
            >
              <Row className="align-items-center">
                <Col md={2} xs={12} className="text-center mb-3 mb-md-0">
                  <IconContainer>
                    <i className="fa fa-wallet"></i>
                  </IconContainer>
                </Col>
                <Col md={10} xs={12}>
                  <MethodTitle>Paga con Mercado Pago <PromoBadge>Recomendado</PromoBadge></MethodTitle>
                  <MethodDescription noMargin={activeMethod !== 'mercadopago'}>
                    Pagar con Mercado Pago es elegir cualquiera de estos medios. Es rápido, seguro y no tiene costo adicional.
                  </MethodDescription>
                </Col>
              </Row>
              
              <DetailContainer active={activeMethod === 'mercadopago'}>
                <MethodDescription>
                  <strong>Beneficios exclusivos:</strong>
                </MethodDescription>
                <ul style={{ color: '#a0a0a0', fontSize: '14px' }}>
                  <li>Acumula puntos que puedes canjear por descuentos</li>
                  <li>Tus pagos están protegidos</li>
                  <li>Proceso simplificado de un solo paso</li>
                </ul>
              </DetailContainer>
            </PaymentMethodCard>

            {/* Tarjeta de crédito */}
            <PaymentMethodCard 
              active={activeMethod === 'credit'} 
              onClick={() => setActiveMethod(activeMethod === 'credit' ? null : 'credit')}
            >
              <Row className="align-items-center">
                <Col md={2} xs={12} className="text-center mb-3 mb-md-0">
                  <IconContainer>
                    <i className="fa fa-credit-card"></i>
                  </IconContainer>
                </Col>
                <Col md={10} xs={12}>
                  <MethodTitle>Tarjeta de crédito <PromoBadge>Hasta 12 MSI</PromoBadge></MethodTitle>
                  <MethodDescription noMargin={activeMethod !== 'credit'}>
                    3, 6 y hasta 12 meses sin intereses en productos seleccionados
                  </MethodDescription>
                </Col>
              </Row>
              
              <DetailContainer active={activeMethod === 'credit'}>
                <LogosContainer>
                  <PaymentIcon color="#1434CB">
                    <i className="fa fa-cc-visa"></i>
                  </PaymentIcon>
                  <PaymentIcon color="#FF5F00">
                    <i className="fa fa-cc-mastercard"></i>
                  </PaymentIcon>
                  <PaymentIcon color="#2E77BC">
                    <i className="fa fa-cc-amex"></i>
                  </PaymentIcon>
                </LogosContainer>
              </DetailContainer>
            </PaymentMethodCard>

            {/* Tarjeta de débito */}
            <PaymentMethodCard 
              active={activeMethod === 'debit'} 
              onClick={() => setActiveMethod(activeMethod === 'debit' ? null : 'debit')}
            >
              <Row className="align-items-center">
                <Col md={2} xs={12} className="text-center mb-3 mb-md-0">
                  <IconContainer>
                    <i className="fa fa-id-card"></i>
                  </IconContainer>
                </Col>
                <Col md={10} xs={12}>
                  <MethodTitle>Tarjeta de débito</MethodTitle>
                  <MethodDescription noMargin={activeMethod !== 'debit'}>
                    Paga a un solo cargo a tu cuenta bancaria
                  </MethodDescription>
                </Col>
              </Row>
              
              <DetailContainer active={activeMethod === 'debit'}>
                <LogosContainer>
                  <PaymentIcon color="#1434CB">
                    <i className="fa fa-cc-visa"></i>
                  </PaymentIcon>
                  <PaymentIcon color="#FF5F00">
                    <i className="fa fa-cc-mastercard"></i>
                  </PaymentIcon>
                </LogosContainer>
              </DetailContainer>
            </PaymentMethodCard>

            {/* Efectivo y transferencia */}
            <PaymentMethodCard 
              active={activeMethod === 'cash'} 
              onClick={() => setActiveMethod(activeMethod === 'cash' ? null : 'cash')}
            >
              <Row className="align-items-center">
                <Col md={2} xs={12} className="text-center mb-3 mb-md-0">
                  <IconContainer>
                    <i className="fa fa-money-bill-wave"></i>
                  </IconContainer>
                </Col>
                <Col md={10} xs={12}>
                  <MethodTitle>Efectivo y transferencia</MethodTitle>
                  <MethodDescription noMargin={activeMethod !== 'cash'}>
                    Es muy simple: cuando termines tu compra, te daremos las instrucciones para que sepas cómo y dónde pagarla.
                  </MethodDescription>
                </Col>
              </Row>
              
              <DetailContainer active={activeMethod === 'cash'}>
                <Row>
                  <Col md={7} xs={12}>
                    <SubtitleText>Depósito y transferencia bancaria</SubtitleText>
                    <LogosContainer>
                      <PaymentIcon large text color="#ec0000">
                        <i className="fa fa-university"></i> Santander
                      </PaymentIcon>
                      <PaymentIcon large text color="#1d73b2">
                        <i className="fa fa-university"></i> BBVA
                      </PaymentIcon>
                      <PaymentIcon large text color="#056dae">
                        <i className="fa fa-university"></i> Citibanamex
                      </PaymentIcon>
                    </LogosContainer>
                  </Col>
                  <Col md={5} xs={12} className="mt-4 mt-md-0">
                    <SubtitleText>Efectivo</SubtitleText>
                    <LogosContainer>
                      <PaymentIcon large text color="#e4032e">
                        <i className="fa fa-store"></i> OXXO
                      </PaymentIcon>
                    </LogosContainer>
                  </Col>
                </Row>
              </DetailContainer>
            </PaymentMethodCard>
          </Container>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default PaymentMethodsModal;