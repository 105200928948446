import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import ProductCard from '../productCard/ProductCard';

const ProductOwlCarousel = ({products, addToKartHandler, smItems = 1, mdItems = 3, lgItems = 5}) => {
  // Configuración mejorada del carrusel
  const options = {
    nav: true,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>'
    ],
    dots: false,
    rewind: true,
    autoplay: false,
    margin: 5, // Reducido el espacio entre las tarjetas
    stagePadding: 5, // Reducido el padding en los extremos
    responsive: {
      0: {
        items: smItems,
        margin: 5,
        stagePadding: 0
      },
      576: {
        items: smItems + 1,
        margin: 5
      },
      768: {
        items: mdItems
      },
      992: {
        items: mdItems + 1
      },
      1200: {
        items: lgItems
      }
    }
  };

  // Evento para manejar las interacciones del usuario con el carrusel
  const events = {
    onDragged: () => {}, 
    onChanged: () => {}
  };

  // Renderizamos solo si hay productos
  if (products.length === 0) {
    return null;
  }

  return (
    <>
      <style>
        {`
          .product-carousel-container {
            position: relative;
            width: 100%;
            padding: 0 5px;
            margin: 20px 0;
          }
          
          /* Estilo para los elementos del carrusel */
          .carousel-item-wrapper {
            padding: 2px;
            box-sizing: border-box;
          }
          
            .owl-nav {
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
                display: flex;
                justify-content: space-between;
                pointer-events: none; /* Para que el contenedor no interfiera con los clics */
                z-index: 10;
            }

            .owl-prev,
            .owl-next {
                width: 40px;
                height: 40px;
                border-radius: 50% !important;
                background-color: rgba(26, 44, 66, 0.85) !important; /* Color oscuro que coincide con el botón */
                color: white !important;
                font-size: 18px !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
                margin: 0 -20px !important;
                pointer-events: auto; /* Habilitamos los clics en los botones */
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                transition: all 0.2s ease;
            }

            .owl-prev:hover,
            .owl-next:hover {
                background-color: rgba(44, 62, 80, 1) !important; /* Color de hover que coincide con el botón */
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
            }

            .owl-prev:focus,
            .owl-next:focus {
                outline: none;
            }
          
          .owl-prev {
            left: 0;
          }
          
          .owl-next {
            right: 0;
          }
          
          /* Ajuste responsive para que los botones no se salgan en pantallas pequeñas */
          @media (max-width: 576px) {
            .owl-prev,
            .owl-next {
              width: 30px;
              height: 30px;
              font-size: 14px !important;
              margin: 0 -10px !important;
            }
          }
          
          /* Desactivar estilo de arrastre */
          .owl-carousel .owl-item {
            touch-action: manipulation;
            -ms-touch-action: manipulation;
          }
          
          /* Corregir altura del contenedor */
          .owl-carousel .owl-stage-outer {
            padding: 5px 0;
          }
          
          /* Clase para animación suave */
          .owl-carousel .owl-item {
            transition: opacity 0.3s ease;
          }
          
          /* Efecto de desvanecimiento para elementos inactivos (opcional) */
          .owl-carousel:hover .owl-item:not(:hover) {
            opacity: 0.9;
          }
        `}
      </style>
      
      <div className="product-carousel-container">
        <OwlCarousel options={options} events={events}>
          {products.map(product => (
            <div key={product.id} className="carousel-item-wrapper">
              <ProductCard
                product={product}
                photos={product.photos}
                model={product.model}
                price={product.price}
                price_with_discount={product.price_with_discount}
                stock={product.stock}
                description={product.description}
                addToKartHandler={addToKartHandler}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </>
  );
};

export default ProductOwlCarousel;