import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Loginbar from '../../shared/professional/Loginbar/Loginbar';
import Navigation from '../../shared/sonoritmo/Navigation/Navigation';
import Footer from '../../shared/footer/footer';
import { updatePaymentStatus } from '../../redux/actions';
import queryString from 'query-string';

// Styled components
const StyledContainer = styled(Container)`
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #e0e0e0;
`;

const StyledRow = styled(Row)`
  padding: 2rem 1rem;
`;

const StatusCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
`;

const StatusCard = styled.div`
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const SuccessIcon = styled.i`
  color: #81c784;
  font-size: 5rem;
  margin-bottom: 1.5rem;
`;

const PendingIcon = styled.i`
  color: #ffb74d;
  font-size: 5rem;
  margin-bottom: 1.5rem;
`;

const ErrorIcon = styled.i`
  color: #cf6679;
  font-size: 5rem;
  margin-bottom: 1.5rem;
`;

const StatusTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const StatusMessage = styled.p`
  color: #b0b0b0;
  margin-bottom: 1rem;
`;

const OrderNumber = styled.p`
  background-color: #1a2c42;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: inline-block;
  font-family: monospace;
  margin-top: 0.5rem;
  border: 1px solid #2a3c52;
`;

const ActionSection = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid #3a3a3a;
`;

const StyledButton = styled(Button)`
  background-color: #1a2c42 !important;
  border-color: #1a2c42 !important;
  color: white !important;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s ease;
  margin-top: 1rem;
  
  &:hover {
    background-color: #263b52 !important;
    border-color: #263b52 !important;
  }
  
  &:active {
    background-color: #102032 !important;
    border-color: #102032 !important;
  }
`;

const PaymentResponse = (props) => {
  useEffect(() => {
    const paymentPayload = queryString.parse(props.location.search);
    props.updatePaymentStatus(paymentPayload);
    
    // Add global styles to match pagination style
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      body {
        background-color: #1a1a1a;
        color: #e0e0e0;
      }
      
      /* Match pagination style */
      .btn-primary {
        background-color: #1a2c42 !important;
        border-color: #1a2c42 !important;
      }
      
      .btn-primary:hover {
        background-color: #263b52 !important;
        border-color: #263b52 !important;
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [props]);

  const getQueryParams = () => queryString.parse(props.location.search);
  const { pathname } = props.location;
  
  const isSuccessResponse = () => {
    const { collection_status } = getQueryParams();
    return collection_status === "approved" && pathname === "/success";
  };
  
  const isPendingResponse = () => {
    const { collection_status } = getQueryParams();
    return collection_status === "pending" && pathname === "/pending";
  };
  
  const isErrorResponse = () => pathname === "/error";
  
  const { external_reference } = getQueryParams();

  return (
    <StyledContainer fluid>
      <Loginbar name="Alberto Ayala Capetillo" customer_id="442424" />
      <Navigation category={"items"} auth={props.auth} />
      
      <StyledRow>
        <StatusCol md="12">
          <StatusCard>
            {isSuccessResponse() && (
              <>
                <SuccessIcon className="fa fa-check-circle" />
                <StatusTitle>Tu compra fue exitosa</StatusTitle>
                <StatusMessage>Revisa tu email constantemente, te estaremos actualizando de cualquier novedad en tu pedido</StatusMessage>
                <OrderNumber>Orden #{external_reference}</OrderNumber>
              </>
            )}
            
            {isPendingResponse() && (
              <>
                <PendingIcon className="fa fa-info-circle" />
                <StatusTitle>Estamos esperando la acreditacion de tu pago</StatusTitle>
                <StatusMessage>Nos pondremos en contacto contigo si no se acredita en los proximos 2 dias habiles</StatusMessage>
                <OrderNumber>Orden #{external_reference}</OrderNumber>
              </>
            )}
            
            {isErrorResponse() && (
              <>
                <ErrorIcon className="fa fa-times-circle" />
                <StatusTitle>No pudimos procesar tu pago</StatusTitle>
                <StatusMessage>Nos pondremos en contacto contigo para ofrecerte otras opciones de pago</StatusMessage>
                <OrderNumber>Orden #{external_reference}</OrderNumber>
              </>
            )}
            
            <ActionSection>
              <StatusMessage>Puedes ver tus ordenes en la seccion <b>Mis compras</b></StatusMessage>
              <a href="/ordenes">
                <StyledButton variant="primary">
                  Ir a <b>Mis compras</b>
                </StyledButton>
              </a>
            </ActionSection>
          </StatusCard>
        </StatusCol>
      </StyledRow>
      
      <Footer />
    </StyledContainer>
  );
};

const mapStateToProps = (state) => ({
  profile: state.global.profile
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentStatus: (paymentPayload) => dispatch(updatePaymentStatus(paymentPayload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentResponse);