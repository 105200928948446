import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Loginbar extends Component {
  render() {
    return (
      <Row className="login-bar hidden">
        <style>
          {`
            .login-bar {
              background-color: #1a2c42;
              color: #e1e7ef;
              font-size: 14px;
              padding: 8px 0;
              border-bottom: 1px solid #2c3e50;
            }
            
            .login-bar .col {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 15px;
            }
            
            .right-border {
              border-right: 1px solid #2c3e50;
            }
            
            .clickable {
              cursor: pointer;
              transition: all 0.2s ease;
            }
            
            .clickable:hover {
              color: #8bb9f0;
              background-color: rgba(255, 255, 255, 0.05);
            }
          `}
        </style>
        <Col className="right-border">Bienvenido al portal de clientes</Col>
        <Col className="clickable">Mi cuenta</Col>
        <Col xs={2}></Col>
        <Col className="right-border">{this.props.name} - {this.props.customer_id}</Col>
        <Col className="clickable">Cerrar sesión</Col>
      </Row>
    );
  }
}

export default Loginbar;