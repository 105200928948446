import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import logo from '../../../bitstruments_logo.png';
import { STORE_CATEGORIES } from '../../../configuration';
import { getProfile, getSubcategories, exchangeRewards } from '../../../redux/actions';

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(props.category || null);
  const [searchQuery, setSearchQuery] = useState('');
  
  useEffect(() => {
    // Load subcategories for all categories
    STORE_CATEGORIES.forEach(item => props.getSubcategories(item));
    
    // Close menu when clicking outside
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.mobile-menu') && !event.target.closest('.menu-toggle')) {
        setIsMenuOpen(false);
      }
      
      if (activeCategory && !event.target.closest('.category-panel') && !event.target.closest('.nav-category')) {
        setActiveCategory(null);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen, activeCategory]);
  
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      window.location = `/search?text=${searchQuery}`;
    }
  };
  
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    window.location = `/search?text=${searchQuery}`;
  };
  
  const login = () => {
    props.auth.login();
    props.getProfile();
  };
  
  const logout = () => {
    props.auth.logout();
    window.location.reload();
  };
  
  const isAuthenticated = () => {
    return props.auth.isAuthenticated();
  };
  
  const getSubcategoriesForCategory = (category) => {
    if (props.subcategoryGroups && props.subcategoryGroups[category]) {
      return props.subcategoryGroups[category].sort((a, b) => 
        a.subcategory.localeCompare(b.subcategory)
      );
    }
    return [];
  };
  
  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };
  
  const cartItemCount = props.kart ? props.kart.reduce((prev, next) => prev + next.amount, 0) : 0;
  
  return (
    <>
      <style>
        {`
          :root {
            --primary-color: #3584e4;
            --primary-hover: #4a93eb;
            --text-color: #e0e0e0;
            --text-muted: #a0a0a0;
            --bg-color: #1f1f1f;
            --navbar-bg: #171717;
            --border-color: rgba(255,255,255,0.1);
            --hover-bg: #2c2c2c;
            --active-bg: #333333;
            --item-radius: 8px;
            --box-shadow: 0 8px 30px rgba(0,0,0,0.3);
            --transition: all 0.2s ease;
          }
          
          html, body {
            touch-action: manipulation;
            -ms-touch-action: manipulation;
            background-color: var(--bg-color);
            color: var(--text-color);
            margin: 0;
            padding: 0;
            min-height: 100vh;
          }
          
          #root {
            min-height: 100vh;
            background-color: var(--bg-color);
          }
          
          .navbar-container {
            background-color: var(--navbar-bg);
            position: sticky;
            top: 0;
            z-index: 1000;
            border-bottom: 1px solid var(--border-color);
            box-shadow: 0 2px 10px rgba(0,0,0,0.2);
          }
          
          .top-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.25rem 0;
            gap: 1rem;
          }
          
          .logo-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 65px; /* Mantén la altura original */
            overflow: visible;
          }

          .logo-container img {
            height: 65px; /* Altura base */
            width: auto;
            transition: var(--transition);
            transform-origin: left center;
            transform: scale(1.5); /* Escala para móvil por defecto */
          }

          /* Para pantallas más grandes (desktop) */
          @media (min-width: 992px) {
            .logo-container img {
              transform: scale(2); /* Escala más grande para desktop */
            }
          }
          
          .logo-container:hover img {
            opacity: 0.9;
          }
          
          .search-container {
            flex-grow: 1;
            max-width: 500px;
            position: relative;
          }
          
          .search-container form {
            display: flex;
            width: 100%;
          }
          
          .search-input {
            width: 100%;
            padding: 0.75rem 1rem;
            border: 1px solid var(--border-color);
            border-radius: var(--item-radius);
            font-size: 0.95rem;
            background-color: #2a2a2a;
            color: var(--text-color);
            transition: var(--transition);
          }
          
          .search-input:focus {
            outline: none;
            border-color: var(--primary-color);
            box-shadow: 0 0 0 2px rgba(53, 132, 228, 0.3);
            background-color: #333;
          }
          
          .search-button {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            color: var(--text-muted);
            cursor: pointer;
            transition: var(--transition);
          }
          
          .search-button:hover {
            color: var(--primary-hover);
          }
          
          .icon-button {
            background: none;
            border: none;
            font-size: 1.1rem;
            color: var(--text-color);
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            transition: var(--transition);
          }
          
          .icon-button:hover {
            background-color: var(--hover-bg);
            color: var(--primary-color);
          }
          
          .icon-button.active {
            background-color: var(--hover-bg);
            color: var(--primary-color);
          }
          
          .cart-badge {
            position: absolute;
            top: 0;
            right: 0;
            background-color: var(--primary-color);
            color: white;
            font-size: 0.7rem;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 600;
            box-shadow: 0 0 0 2px var(--navbar-bg);
          }
          
          .categories-row {
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem 0;
            border-top: 1px solid var(--border-color);
            justify-content: center;
            flex-wrap: wrap;
          }
          
          .category-button {
            background: none;
            border: none;
            font-size: 0.95rem;
            color: var(--text-color);
            padding: 0.5rem 0.75rem;
            cursor: pointer;
            font-weight: 500;
            border-radius: var(--item-radius);
            transition: var(--transition);
            position: relative;
          }
          
          .category-button:hover {
            color: var(--primary-color);
            background-color: var(--hover-bg);
          }
          
          .category-button.active {
            color: var(--primary-color);
            background-color: var(--hover-bg);
          }
          
          .category-panel {
            position: absolute;
            left: 0;
            right: 0;
            background-color: var(--bg-color);
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            box-shadow: var(--box-shadow);
            padding: 2rem 0;
            z-index: 100;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: var(--transition);
          }
          
          .category-panel.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
          
          .subcategory-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 1.5rem;
          }
          
          .subcategory-item {
            display: flex;
            align-items: center;
            border-radius: var(--item-radius);
            padding: 0.75rem;
            transition: var(--transition);
            text-decoration: none;
            color: var(--text-color);
          }
          
          .subcategory-item:hover {
            background-color: var(--hover-bg);
            transform: translateY(-2px);
            color: var(--primary-hover);
          }
          
          .subcategory-image {
            width: 40px;
            height: 40px;
            object-fit: contain;
            margin-right: 0.75rem;
            border-radius: var(--item-radius);
            background-color: #333;
          }
          
          .subcategory-name {
            font-size: 0.9rem;
            font-weight: 500;
          }
          
          .menu-toggle {
            display: none;
            background: none;
            border: none;
            font-size: 1.5rem;
            padding: 0.5rem;
            cursor: pointer;
            color: var(--text-color);
          }
          
          .mobile-menu {
            position: fixed;
            top: 0;
            right: 0;
            width: 300px;
            height: 100vh;
            background-color: var(--bg-color);
            z-index: 2000;
            box-shadow: -5px 0 30px rgba(0, 0, 0, 0.5);
            padding: 1.5rem;
            transform: translateX(100%);
            transition: transform 0.3s ease;
            overflow-y: auto;
          }
          
          .mobile-menu.open {
            transform: translateX(0);
          }
          
          .mobile-menu-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;
          }
          
          .close-menu {
            background: none;
            border: none;
            font-size: 1.25rem;
            cursor: pointer;
            color: var(--text-color);
          }
          
          .mobile-search {
            margin-bottom: 1.5rem;
          }
          
          .mobile-categories {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
          
          .mobile-category {
            color: white;
            padding: 0.75rem;
            font-weight: 500;
            cursor: pointer;
            border-radius: var(--item-radius);
            transition: var(--transition);
          }
          
          .mobile-category:hover {
            background-color: var(--hover-bg);
            color: var(--primary-hover);
          }
          
          .mobile-subcategories {
            margin-top: 0.5rem;
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
          
          .mobile-subcategory {
            padding: 0.5rem 0.75rem;
            text-decoration: none;
            color: var(--text-color);
            font-size: 0.9rem;
            border-radius: var(--item-radius);
            transition: var(--transition);
          }
          
          .mobile-subcategory:hover {
            background-color: var(--hover-bg);
            color: var(--primary-color);
          }
          
          .mobile-actions {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
          }
          
          .mobile-action-button {
            padding: 0.75rem;
            text-decoration: none;
            color: var(--text-color);
            border-radius: var(--item-radius);
            display: flex;
            align-items: center;
            gap: 0.75rem;
            transition: var(--transition);
          }
          
          .mobile-action-button:hover {
            background-color: var(--hover-bg);
            color: var(--primary-color);
          }
          
          .user-info {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            margin-top: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            background-color: var(--active-bg);
            border-radius: var(--item-radius);
            border: 1px solid var(--border-color);
          }
          
          .user-avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: var(--primary-color);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
          }
          
          .user-dropdown {
            position: relative;
            margin-left: 10px;
          }
          
          .user-dropdown-toggle {
            background: none;
            border: none;
            color: var(--text-color);
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            padding: 8px 12px;
            border-radius: var(--item-radius);
            transition: var(--transition);
          }
          
          .user-dropdown-toggle:hover {
            background-color: var(--hover-bg);
          }
          
          .user-dropdown-menu {
            position: absolute;
            top: 100%;
            right: 0;
            min-width: 200px;
            background-color: var(--bg-color);
            border-radius: var(--item-radius);
            border: 1px solid var(--border-color);
            box-shadow: var(--box-shadow);
            padding: 8px 0;
            z-index: 1000;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
            transition: var(--transition);
          }
          
          .user-dropdown-menu.open {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
          
          .user-dropdown-item {
            padding: 8px 16px;
            color: var(--text-color);
            font-size: 0.9rem;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 8px;
            transition: var(--transition);
          }
          
          .user-dropdown-item:hover {
            background-color: var(--hover-bg);
            color: var(--primary-color);
          }
          
          .user-dropdown-divider {
            border-top: 1px solid var(--border-color);
            margin: 8px 0;
          }
          
          .mobile-search-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background-color: var(--navbar-bg);
            padding: 1rem;
            z-index: 1500;
            display: flex;
            box-shadow: 0 5px 15px rgba(0,0,0,0.3);
            transform: translateY(-100%);
            transition: transform 0.3s ease;
          }
          
          .mobile-search-container.open {
            transform: translateY(0);
          }
          
          .mobile-search-input {
            flex: 1;
            padding: 0.75rem 1rem;
            border: 1px solid var(--border-color);
            border-radius: var(--item-radius);
            font-size: 0.95rem;
            background-color: #2a2a2a;
            color: var(--text-color);
          }
          
          .close-search {
            background: none;
            border: none;
            padding: 0 0.75rem;
            color: var(--text-color);
            font-size: 1.25rem;
            cursor: pointer;
          }
          
          @media (max-width: 992px) {
            .search-container {
              display: none;
            }
            
            .categories-row {
              display: none;
            }
            
            .menu-toggle {
              display: block;
            }
            
            .action-buttons {
              display: none;
            }
            
            .user-dropdown {
              display: none;
            }
          }
          
          @media (min-width: 993px) {
            .mobile-search-button {
              display: none;
            }
          }
        `}
      </style>
      
      {/* Main Navbar */}
      <div className="navbar-container">
        <Container>
          {/* Top Bar */}
          <div className="top-bar">
            <a href="/" className="logo-container">
              <img src={logo} alt="Bitstruments logo" />
            </a>
            
            <div className="search-container">
              <form onSubmit={handleSearchSubmit}>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Busca instrumentos, marcas..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleSearch}
                />
                <button type="submit" className="search-button">
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
            
            <div className="d-flex align-items-center">
              <button 
                className="icon-button mobile-search-button" 
                onClick={() => setIsSearchOpen(true)}
                aria-label="Search"
              >
                <i className="fa fa-search"></i>
              </button>
              
              {/* Carrito visible tanto en móvil como en desktop */}
              {!REACT_APP_INFORMATIVE_MODE && (
                <a href="/cart" className="icon-button me-2">
                  <i className="fa fa-shopping-cart"></i>
                  {cartItemCount > 0 && (
                    <span className="cart-badge">{cartItemCount}</span>
                  )}
                </a>
              )}
              
              {!REACT_APP_INFORMATIVE_MODE && !isAuthenticated() && (
                <a 
                  href="#"
                  onClick={login}
                  className="icon-button action-buttons"
                >
                  <i className="fa fa-sign-in"></i>
                </a>
              )}
              
              {!REACT_APP_INFORMATIVE_MODE && isAuthenticated() && props.profile && (
                <div className="user-dropdown">
                  <button 
                    className="user-dropdown-toggle"
                    onClick={() => {
                      const menu = document.querySelector('.user-dropdown-menu');
                      menu.classList.toggle('open');
                    }}
                  >
                    <div className="user-avatar">
                      {(props.profile.first_name || '').charAt(0)}
                    </div>
                    <span>{props.profile.first_name || ''}</span>
                    <i className="fa fa-chevron-down"></i>
                  </button>
                  
                  <div className="user-dropdown-menu">
                    <div className="user-dropdown-item" style={{ pointerEvents: 'none' }}>
                      <i className="fa fa-user"></i>
                      {props.profile.first_name || ''} {props.profile.last_name || ''}
                    </div>
                    
                    <div className="user-dropdown-divider"></div>
                    
                    <a href="/ordenes" className="user-dropdown-item">
                      <i className="fa fa-shopping-bag"></i>
                      Mis Compras
                    </a>
                    
                    <div className="user-dropdown-divider"></div>
                    
                    <a 
                      href="#"
                      onClick={logout}
                      className="user-dropdown-item"
                    >
                      <i className="fa fa-sign-out"></i>
                      Cerrar Sesión
                    </a>
                  </div>
                </div>
              )}
              
              <button 
                className="menu-toggle"
                onClick={() => setIsMenuOpen(true)}
                aria-label="Menu"
              >
                <i className="fa fa-bars"></i>
              </button>
            </div>
          </div>
          
          {/* Categories Row */}
          <div className="categories-row">
            {STORE_CATEGORIES.map((category) => (
              <button
                key={category}
                className={`category-button ${activeCategory === category ? 'active' : ''}`}
                onClick={() => toggleCategory(category)}
              >
                {category}
              </button>
            ))}
            
            <a href="/sucursales" className="category-button">
              Sucursales
            </a>
          </div>
        </Container>
        
        {/* Category Panels */}
        {STORE_CATEGORIES.map((category) => (
          <div 
            key={`panel-${category}`}
            className={`category-panel ${activeCategory === category ? 'active' : ''}`}
          >
            <Container>
              <div className="subcategory-grid">
                {getSubcategoriesForCategory(category).map((subcategory) => (
                  <a
                    key={subcategory.subcategory}
                    href={`/${category}?subcategory=${subcategory.subcategory}`.toLowerCase()}
                    className="subcategory-item"
                  >
                    {subcategory.picture ? (
                      <img 
                        src={subcategory.picture} 
                        alt={subcategory.subcategory}
                        className="subcategory-image"
                      />
                    ) : (
                      <div className="subcategory-image" style={{ backgroundColor: "#333" }}></div>
                    )}
                    <span className="subcategory-name">{subcategory.subcategory}</span>
                  </a>
                ))}
              </div>
            </Container>
          </div>
        ))}
      </div>
      
      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-header">
          <h4 style={{ color: "var(--text-color)", margin: 0 }}>Menu</h4>
          <button className="close-menu" onClick={() => setIsMenuOpen(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        
        <div className="mobile-search">
          <input
            type="text"
            className="search-input"
            placeholder="Busca instrumentos..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearch}
          />
        </div>
        
        <div className="mobile-categories">
          {STORE_CATEGORIES.map((category) => {
            const isActive = selectedCategory === category;
            const subcategories = getSubcategoriesForCategory(category);
            
            return (
              <div key={`mobile-${category}`}>
                <div 
                  className="mobile-category"
                  onClick={() => setSelectedCategory(isActive ? null : category)}
                >
                  {category}
                  <i className={`fa fa-chevron-${isActive ? 'up' : 'down'} float-end`}></i>
                </div>
                
                {isActive && subcategories.length > 0 && (
                  <div className="mobile-subcategories">
                    {subcategories.map((subcategory) => (
                      <a
                        key={subcategory.subcategory}
                        href={`/${category}?subcategory=${subcategory.subcategory}`.toLowerCase()}
                        className="mobile-subcategory"
                      >
                        {subcategory.subcategory}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        
        <div className="mobile-actions">
          <a href="/sucursales" className="mobile-action-button">
            <i className="fa fa-map-marker-alt"></i>
            Sucursales
          </a>
          
          {!REACT_APP_INFORMATIVE_MODE && (
            <a href="/cart" className="mobile-action-button">
              <i className="fa fa-shopping-cart"></i>
              Carrito
              {cartItemCount > 0 && (
                <span className="cart-badge ms-auto">{cartItemCount}</span>
              )}
            </a>
          )}
          
          {!REACT_APP_INFORMATIVE_MODE && !isAuthenticated() && (
            <a 
              href="#"
              onClick={login}
              className="mobile-action-button"
            >
              <i className="fa fa-sign-in-alt"></i>
              Iniciar Sesión
            </a>
          )}
          
          {!REACT_APP_INFORMATIVE_MODE && isAuthenticated() && (
            <>
              <a href="/ordenes" className="mobile-action-button">
                <i className="fa fa-shopping-bag"></i>
                Mis Compras
              </a>
              
              <a 
                href="#"
                onClick={logout}
                className="mobile-action-button"
              >
                <i className="fa fa-sign-out-alt"></i>
                Cerrar Sesión
              </a>
            </>
          )}
        </div>
        
        {!REACT_APP_INFORMATIVE_MODE && isAuthenticated() && props.profile && (
          <div className="user-info">
            <div className="user-avatar">
              {(props.profile.first_name || '').charAt(0)}
            </div>
            <div>
              <div className="fw-bold">{props.profile.first_name || ''} {props.profile.last_name || ''}</div>
              <div style={{ fontSize: "0.8rem", color: "var(--text-muted)" }}>{props.profile.email || ''}</div>
            </div>
          </div>
        )}
      </div>
      
      {/* Mobile Search Panel */}
      <div className={`mobile-search-container ${isSearchOpen ? 'open' : ''}`}>
        <form onSubmit={handleSearchSubmit} className="d-flex w-100 align-items-center">
          <input
            type="text"
            className="mobile-search-input"
            placeholder="Buscar productos..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearch}
            autoFocus
          />
          <button type="submit" className="search-button me-2">
            <i className="fa fa-search"></i>
          </button>
          <button 
            type="button" 
            className="close-search"
            onClick={() => setIsSearchOpen(false)}
          >
            <i className="fa fa-times"></i>
          </button>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    kart: state.global.kart || [],
    profile: state.global.profile || {},
    subcategories: state.commerce.subcategories,
    subcategoryGroups: state.commerce.subcategoryGroups || {},
    rewards: state.commerce.rewards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  getSubcategories: (category) => dispatch(getSubcategories(category, true)),
  exchangeRewards: (userId, code, points) => dispatch(exchangeRewards(userId, code, points))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);