import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Loginbar from "../../../shared/professional/Loginbar/Loginbar";
import Navigation from "../../../shared/sonoritmo/Navigation/Navigation";
import {
  getItemDetail,
  addItemToKart,
  getAdvertisementById,
  createItemQuestion,
  getItemQuestions
} from "../../../redux/actions";
import Footer from "../../../shared/footer/footer";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Markup } from "interweave";
import ProductCarousel from "../../../shared/productCarousel/ProductCarousel";
import { currencyFormat, urlFormat } from "../../../utils/format";
import { Whatsapp } from "react-social-sharing";
import PremiumProductModal from "../../../shared/premiumProductModal/PremiumProductModal";
import CreditPriceMonths from "../../../shared/creditPriceMonths/CreditPriceMonths";
import Loader from "../../../shared/loader/loader";
import { BASE_DOMAIN } from "../../../configuration";
import ReactGA from "react-ga";
import MetaTags from "react-meta-tags";
import styled from "styled-components";
import { FaShare, FaCartPlus, FaTag } from "react-icons/fa";

const REACT_APP_INFORMATIVE_MODE = process.env.REACT_APP_INFORMATIVE_MODE === 'true';

// Styled components for dark mode
const DarkContainer = styled(Container)`
  background-color: #121212;
  color: #e0e0e0;
  min-height: 100vh;
  padding-bottom: 100px; /* Space for footer */
  position: relative;
`;

const MainContentWrapper = styled.div`
  margin-bottom: 120px; /* Extra space to prevent footer overlap */
`;

const ProductContentContainer = styled(Container)`
  padding: 0;
  max-width: 1200px;
`;

const ProductContent = styled(Row)`
  background-color: #1e1e1e;
  border-radius: 12px;
  margin: 25px 0;
  padding: 30px 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
`;

const ProductTitle = styled.h4`
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 1.8rem;
`;

const ProductDescription = styled.div`
  text-align: justify;
  line-height: 1.6;
  color: #d0d0d0;
  
  h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  ul, ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const ProductCarouselWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
  
  .responsive-image-wrapper {
    height: 49vh !important;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
`;

const ProductInfoCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const SoldItems = styled.div`
  color: #a0a0a0;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
`;

const PriceContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background-color: #262626;
  border-radius: 10px;
`;

const PriceWithoutDiscount = styled.div`
  color: #a0a0a0;
  text-decoration: line-through;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: center;
`;

const DiscountTag = styled.span`
  background-color: #e53e3e;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  
  svg {
    margin-right: 4px;
  }
`;

const FinalPrice = styled.div`
  color: #4cd964;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
`;

const AddToCartButton = styled(Button)`
  background-color: #3182ce;
  border-color: #3182ce;
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: 600;
  margin-top: 15px;
  transition: all 0.2s ease;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: #2c5282;
    border-color: #2c5282;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  svg {
    margin-left: 8px;
    font-size: 1.2rem;
  }
`;

const SocialShareContainer = styled.div`
  margin-top: 25px;
  padding: 15px;
  background-color: #262626;
  border-radius: 10px;
  
  h6 {
    color: #a0a0a0;
    margin-bottom: 10px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  }
  
  h6 svg {
    margin-right: 8px;
    color: #63b3ed;
  }
  
  .social-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
  }
  
  button {
    border-radius: 50%;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: translateY(-3px);
    }
  }
`;

const PackageItems = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
  
  li {
    margin-bottom: 8px;
    color: #d0d0d0;
    position: relative;
    
    &:before {
      content: "•";
      color: #4cd964;
      font-weight: bold;
      position: absolute;
      left: -15px;
    }
  }
`;

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  .loader {
    width: 60px;
    height: 60px;
  }
`;

const NotFoundMessage = styled.div`
  text-align: center;
  width: 100%;
  color: #fc8181;
  padding: 60px 20px;
  
  h6 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  p {
    color: #a0a0a0;
  }
`;

const FooterWrapper = styled.div`
  margin-top: 60px;
  width: 100%;
`;

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemhash: this.props.match.params.itemhash,
      selectedCategory: this.props.match.params.category
        ? this.props.match.params.category
        : "guitarras",
      questionInputValue: ""
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.handleQuestionInputChange = this.handleQuestionInputChange.bind(this);
    this.handleCreateQuestionButton = this.handleCreateQuestionButton.bind(this);
    ReactGA.pageview(this.props.location.pathname);
  }

  handleQuestionInputChange(event) {
    this.setState({ ...this.state, questionInputValue: event.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.type === "item") {
      this.props.getItemDetail(this.state.itemhash);
    } else {
      this.props.getAdvertisementById(this.state.itemhash);
    }
    //this.props.getItemQuestions(this.state.itemhash, this.props.type);
  }

  getShareUrl() {
    return this.props.product
      ? `${BASE_DOMAIN}/${this.props.type}/${urlFormat(
          this.props.product.model
        )}/${this.props.product.id}`
      : "";
  }

  getSocialMessage() {
    let message = !this.isPremium()
      ? `${this.props.product.model} a solo $${currencyFormat(
          this.getRealPrice()
        )}`
      : `${this.props.product.model} solo por Sonoritmo`;
    return message;
  }

  clickHandler() {
    this.props.addItemToKart("kart", { ...this.props.product, amount: 1 });
  }

  itemHasDiscount() {
    return this.props.product.price_with_discount < this.props.product.price;
  }

  getDiscountPercentage() {
    if (!this.itemHasDiscount()) return 0;
    
    const originalPrice = this.props.product.price;
    const discountedPrice = this.props.product.price_with_discount;
    const discountAmount = originalPrice - discountedPrice;
    const discountPercentage = Math.round((discountAmount / originalPrice) * 100);
    
    return discountPercentage;
  }

  getRealPrice() {
    return this.itemHasDiscount()
      ? this.props.product.price_with_discount
      : this.props.product.price;
  }

  isPremium() {
    return this.getRealPrice() < 0.1 || this.props.product.items;
  }

  getPackageItems() {
    return this.props.product.items.map(item => (
      <li key={item.model}>
        {item.quantity} x {item.model} {item.name}
      </li>
    ));
  }

  capitalize(s) {
    if (typeof s !== "string") return "";
    const strLower = s.toLocaleLowerCase();
    return (
      strLower
        .toLowerCase()
        .charAt(0)
        .toUpperCase() + strLower.slice(1)
    );
  }

  handleCreateQuestionButton() {
    if(!this.props.profile.id) {
      this.props.auth.login();
      return;
    }
    const questionStr = this.state.questionInputValue;
    const question = {
      id: new Date().getTime(),
      question: questionStr,
      answer: null,
      timestamp: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
      user: this.props.profile,
      itemId: this.props.product.id,
      path: window.location.href,
    };
    this.props.createItemQuestion(
      this.props.product.id,
      this.props.type,
      question
    );
    this.setState({ questionInputValue: "" });
  }

  render() {
    let socialURL = this.getShareUrl();
    let photos = [];

    let itemTitle = "";
    if (this.props.product)
      itemTitle =
        (this.props.product.brand ? this.props.product.brand + " " : "") +
        (this.props.product.title ? this.props.product.title + " - " : "") +
        this.props.product.model;

    let itemDescription = this.capitalize(itemTitle);

    if (this.props.product)
      photos = this.props.product.photos
        ? this.props.product.photos
        : this.props.product.pictures.map(pic => pic.url);
        
    return (
      <DarkContainer fluid>
        <Loginbar name="Alberto Ayala Capetillo" customer_id="442424" />
        <Navigation
          category={this.state.selectedCategory}
          auth={this.props.auth}
        />
        
        <MainContentWrapper>
          <ProductContentContainer>
            {this.props.product ? (
              <ProductContent>
                <Col md={6} lg={7} className="mb-4 mb-md-0">
                  <ProductCarouselWrapper>
                    <ProductCarousel photos={photos} />
                  </ProductCarouselWrapper>
                  
                  <ProductDescription className="d-block d-md-none">
                    <ProductTitle>{itemTitle}</ProductTitle>
                    <Markup content={this.props.product.description} />
                    {this.props.product.items && (
                      <div className="mt-4">
                        <h6 className="text-white mb-2">Este paquete incluye:</h6>
                        <PackageItems>{this.getPackageItems()}</PackageItems>
                      </div>
                    )}
                  </ProductDescription>
                </Col>
                
                <Col md={6} lg={5}>
                  <ProductTitle className="d-none d-md-block">{itemTitle}</ProductTitle>
                  
                  <ProductInfoCard>
                    <SoldItems>
                      {this.props.product.sold_units
                        ? `${this.props.product.sold_units} ${
                            this.props.product.sold_units === 1
                              ? "Vendido"
                              : "Vendidos"
                          }`
                        : null}
                    </SoldItems>
                    
                    <PriceContainer>
                      {this.itemHasDiscount() && !this.isPremium() && (
                        <PriceWithoutDiscount>
                          {`$ ${currencyFormat(this.props.product.price)}`}
                          <DiscountTag>
                            <FaTag /> {this.getDiscountPercentage()}% OFF
                          </DiscountTag>
                        </PriceWithoutDiscount>
                      )}
                      
                      {!this.isPremium() && (
                        <FinalPrice>
                          {`$ ${currencyFormat(this.getRealPrice())}`}
                        </FinalPrice>
                      )}
                      
                      <CreditPriceMonths
                        price={this.getRealPrice()}
                        hasItems={this.props.product.items}
                        hasInstallments={this.props.product.hasInstallments}
                        centered
                      />
                    </PriceContainer>
                    
                    {!this.isPremium() && !REACT_APP_INFORMATIVE_MODE && (
                      <AddToCartButton onClick={this.clickHandler}>
                        Agregar al carrito <FaCartPlus />
                      </AddToCartButton>
                    )}
                    
                    {this.isPremium() && (
                      <div className="text-center mt-3">
                        <PremiumProductModal model={this.props.product.model} />
                      </div>
                    )}
                    
                    <SocialShareContainer>
                      <h6><FaShare /> Compartir este producto</h6>
                      <div className="social-buttons">
                        <Whatsapp
                          link={socialURL}
                          message={this.getSocialMessage()}
                        />
                      </div>
                    </SocialShareContainer>
                  </ProductInfoCard>
                </Col>
                
                <Col md={12} className="d-none d-md-block mt-4">
                  <ProductDescription>
                    <h5 className="text-white mb-3">Descripción del producto</h5>
                    <Markup content={this.props.product.description} />
                    {this.props.product.items && (
                      <div className="mt-4">
                        <h6 className="text-white mb-2">Este paquete incluye:</h6>
                        <PackageItems>{this.getPackageItems()}</PackageItems>
                      </div>
                    )}
                  </ProductDescription>
                </Col>
                
                <MetaTags>
                  <title>{itemTitle}</title>
                  <meta name="description" content={itemTitle} />
                  <meta property="og:title" content={itemDescription} />
                  <meta property="og:type" content="product" />
                  <meta property="og:description" content={itemDescription} />
                  <meta property="og:url" content={window.location.href} />
                  <meta property="og:image" content={photos[0]} />
                  <meta
                    property="product:brand"
                    content={
                      this.props.product.brand
                        ? this.props.product.brand
                        : "Sonoritmo"
                    }
                  />
                  <meta property="product:availability" content="in stock" />
                  <meta property="product:condition" content="new" />
                  <meta
                    property="product:price:amount"
                    content={!this.isPremium() ? this.getRealPrice() : 0}
                  />
                  <meta property="product:price:currency" content="MXN" />
                  <meta
                    property="product:retailer_item_id"
                    content={this.props.product.id}
                  />
                </MetaTags>
              </ProductContent>
            ) : !this.props.isLoading ? (
              <ProductContent>
                <NotFoundMessage>
                  <h6>404: Artículo no encontrado</h6>
                  <p>Lo sentimos, el producto que buscas no está disponible.</p>
                </NotFoundMessage>
              </ProductContent>
            ) : (
              <ProductContent>
                <StyledLoader>
                  <Loader className="loader" />
                </StyledLoader>
              </ProductContent>
            )}
          </ProductContentContainer>
        </MainContentWrapper>
        
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </DarkContainer>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isLoading: state.global.isLoading,
    profile: state.global.profile,
    product: state.commerce.product
      ? state.commerce.product
      : state.commerce.advertisement,
    questions: state.commerce.questions
  };
};

const mapDispatchToProps = dispatch => ({
  getItemDetail: itemId => dispatch(getItemDetail(itemId)),
  getAdvertisementById: advertisementId =>
    dispatch(getAdvertisementById(advertisementId)),
  addItemToKart: (kartName, item) => dispatch(addItemToKart(kartName, item)),
  createItemQuestion: (itemId, type, question) =>
    dispatch(createItemQuestion(itemId, type, question)),
  getItemQuestions: (itemId, type) => dispatch(getItemQuestions(itemId, type))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);